.find-property {
    .description {
        text-align: center;
        padding: 90px;

        .btn {
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 60px 0 80px;
            text-align: left;
        }
    }

    .steps {
        display: flex;
        justify-content: space-between;
        margin-bottom: 75px;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        .item {
            box-shadow: 0px 3px 6px #0000001A;
            -webkit-box-shadow: 0px 3px 6px #0000001A;
            -moz-box-shadow: 0px 3px 6px #0000001A;
            width: calc(100% / 3 - 15px);
            position: relative;
            height: 270px;
            padding: 70px 40px 50px;
            border-radius: 10px;

            @include media-breakpoint-down(sm) {
                width: 100%;
                margin-bottom: 60px;
                text-align: center;
            }

            .step {
                font-size: 40px;
                color: $color-0;
                background-color: $color-2;
                border-radius: 50%;
                width: 58px;
                height: 58px;
                text-align: center;
                position: absolute;
                top: -29px;
                left: 50%;
                margin-left: -29px;
            }

            .title-3 {
                color: $color-5;
                margin-bottom: 20px;
            }
        }
    }
}

.find-property-form {
    background-color: #fbfbfb;
    padding: 80px 0 100px;

    @include media-breakpoint-down(sm) {
        padding: 50px 0 80px;
    }

    .block-items {
        margin-top: -10px;
        text-align: center;

        @include media-breakpoint-down(sm) {
            margin: 0 0 25px;
        }

        .text {
            margin: 0;
        }

        .actions {
            .btn {
                margin: 0 5px;
            }
        }
    }

    .custom-control {
        margin-bottom: 25px;

        .custom-control-label {
            padding: 6px 0 0 7px;
        }
    }

    .btn-1 {
        display: block;
        width: 100%;
    }
}

.favorite-properties {
    .description {
        text-align: center;
        padding: 90px 0 40px;

        @include media-breakpoint-down(sm) {
            padding: 60px 0 30px;
        }
    }

    .favorite-empty {
        border: 1px solid $color-8;
        border-radius: 10px;
        padding: 70px 0 60px;

        .title {
            color: $color-8;
        }

        .text {
            color: $color-7;

            .icon {
                height: 22px;
                margin-left: 10px;
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 70px 50px 60px;
            margin-bottom: 30px;
        }
    }

    .properties-list {
        margin-bottom: 60px;

        .card-property {
            margin-bottom: 20px;

            .description {
                @include media-breakpoint-down(sm) {
                    padding: 20px 20px 35px;
                }
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 30px;
            }
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
        }
    }

    .popular-search {
        background-color: #FBFBFB;
    }
}

.favorite-modal {
    width: 100%;
    max-width: 352px;
    padding: 35px;
    border: 1px solid $color-3;
    border-radius: 10px 0 0 10px;
    position: absolute;
    z-index: 9;
    background-color: $color-0;
    top: 100px;
    right: 0;

    .title-3 {
        margin-bottom: 35px;
    }

    .btn-close {
        position: absolute;
        right: 5px;
        top: 5px;
        padding: 10px 15px;
        background-color: transparent;

        .img {
            height: 16px;
        }
    }

    .properties-block {
        .property-item {
            margin-bottom: 20px;

            .item {
                display: flex;
                border: 1px solid $color-3;
                border-radius: 5px;
                max-width: 272px;
                width: 100%;
                padding: 0;

                .image {
                    width: 100%;
                    height: 90px;
                    max-width: 127px;

                    position: relative;

                    .modality {
                        font-size: 12px;
                        color: $color-1;
                        text-align: center;
                        padding: 1px;
                        background-color: $color-2;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }
                }

                .info {
                    padding: 13px 15px;

                    .text {
                        margin-bottom: 4px;

                        &:nth-child(2) {
                            color: $color-7;
                            margin-bottom: 5px;
                        }

                        &:nth-child(3) {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .btn-remove {
                font-size: 12px;
                color: $color-8;
                margin: 5px auto 0;
                display: block;
                padding: 7px 5px 3px;
                background-color: transparent;

                &:before {
                    content: url('../../images/icons/cancel.png');
                    vertical-align: top;
                    margin-right: 5px;
                }

            }
        }

        .info-favorite {
            background-color: $color-1;
            margin: 0 -35px 25px;
            text-align: center;
            padding: 12px 0;

            .text {
                margin: 0;
            }
        }

        .btn-3 {
            margin: 0 auto;
            display: block;
            max-width: 140px;
            padding: 12px;
        }
    }
}

.advertise-property {
    .content {
        margin-top: -115px;
        margin-bottom: 70px;

        @include media-breakpoint-down(sm) {
            margin: 0;
        }

        .description {
            padding-top: 210px;

            @include media-breakpoint-down(sm) {
                padding-top: 40px;
            }

            .title-1 {
                margin-bottom: 20px;

                @include media-breakpoint-down(sm) {
                    text-align: center;
                }
            }

            .title-2 {
                margin-bottom: 30px;

                @include media-breakpoint-down(sm) {
                    text-align: center;
                }
            }

            .steps {
                .item {
                    padding-left: 90px;
                    padding-bottom: 20px;
                    position: relative;

                    .step {
                        font-size: 26px;
                        color: $color-1;
                        width: 60px;
                        height: 60px;
                        border: 2px solid $color-1;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }

        .info {
            margin-bottom: 40px;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }

            .text {
                margin: 0;
            }
        }

        .btn-whats {
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }

        .block-form {
            box-shadow: -1px 3px 14px -3px #0000001A;
            -webkit-box-shadow: -1px 3px 14px -3px #0000001A;
            -moz-box-shadow: -1px 3px 14px -3px #0000001A;
            background-color: $color-0;
            padding: 35px 25px;
            border-radius: 10px;
            margin-top: 50px;

            @include media-breakpoint-down(sm) {
                margin-top: 55px;
                margin-bottom: 50px;
            }

            .title {
                margin-bottom: 15px;

                &.title-3 {
                    text-align: center;
                    color: $color-5;
                    margin-bottom: 20px;
                }
            }

            .form {
                .form-group {
                    margin-bottom: 15px;
                }

                .btn {
                    display: block;
                    width: 100%;
                }

                .block-items {
                    margin-bottom: 35px;

                    .text {
                        margin-bottom: 0;
                        text-align: center;
                    }
    
                    .actions {
                        text-align: center;
                        display: flex;
    
                        .btn {
                            margin: 0 5px;
                        }
                    }
                }
            }
        }
    }

    .banner {
        margin-bottom: -100px;

        .over {
            @include media-breakpoint-down(sm) {
                overflow: hidden;
            }
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: -290px;
        }
        
        .item {
            height: 250px;
            width: 100%;
            padding: 50px 0 40px 70px;
            border-radius: 10px;
            background-color: $color-2;
            position: relative;

            @include media-breakpoint-down(md) {
                padding: 50px 0 40px 30px;
            }

            @include media-breakpoint-down(sm) {
                height: 585px;
                padding: 50px 30px;
            }

            .img {
                position: absolute;
                right: 90px;
                bottom: 0;
                z-index: 1;

                @include media-breakpoint-down(md) {
                    right: 0;
                }

                @include media-breakpoint-down(sm) {
                    right: auto;
                    bottom: 0;
                    z-index: 1;
                    left: 50%;
                    margin-left: -130px;
                }
            }

            .triangle {
                height: 250px;
                overflow: hidden;
                border-radius: 10px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                &:after {
                    content: '';
                    display: block;
                    width: 0; 
                    height: 0; 
                    border-left: 300px solid transparent;
                    border-right: 0 solid transparent;
                    border-top: 650px solid $color-1;
                    right: 0;
                    position: absolute;
                    top: 0;

                    @include media-breakpoint-down(sm) {
                        border-left: 0 solid $color-1;
                        border-right: 390px solid $color-1;
                        border-top: 100px solid transparent;
                        top: -100px;
                    }
                }

                @include media-breakpoint-down(sm) {
                    height: 185px;
                    background-color: $color-1;
                    top: auto;
                    border-radius: 0 0 10px 10px;
                    overflow: inherit;
                }
            }
            
            .block-info {
                display: flex;

                @include media-breakpoint-down(sm) {
                    display: block;
                }

                .block {
                    .title {
                        color: $color-0;
                        margin-bottom: 30px;

                        @include media-breakpoint-down(sm) {
                            margin-bottom: 10px;
                        }
                    }

                    .text {
                        color: $color-0;

                        @include media-breakpoint-down(sm) {
                            margin-bottom: 30px;
                        }
                    }

                    &:first-child {
                        max-width: 266px;
                        width: 100%;

                        @include media-breakpoint-down(sm) {
                            margin: 0 auto;
                        }
                    }

                    &:last-child {
                        max-width: 168px;
                        width: 100%;
                        margin-left: 30px;
                    }
                }
            }
        }
    }

    .reasons {
        background-color: $color-4;
        padding: 190px 0 130px;

        @include media-breakpoint-down(sm) {
            padding: 350px 0 85px;
        }
        
        .block-title {
            text-align: center;
            margin-bottom: 50px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 40px;
            }
        }

        .item {
            position: relative;
            padding-left: 50px;

            .icon {
                position: absolute;
                top: 0;
                left: 0;
            }

            @include media-breakpoint-down(sm) {
                padding-bottom: 20px;
            }
        }

        .action {
            text-align: center;
        }
    }
}

.rental-calculator {
    .page-header {
        height: auto;
        padding: 90px 0 100px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .title-1, .text-1 {
            color: $color-0;
            margin-bottom: 30px;
        }

        .title-7 {
            color: $color-1;
        }

        .btn {
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    .content {
        .trend {
            background-color: $color-4;
            padding: 90px 0;
            text-align: center;

            .block-title {
                .title {
                    margin-bottom: 20px;
                }

                .text {
                    margin-bottom: 40px;
                }
            }

            .topics {
                .topic {
                    .icon {
                        margin-bottom: 25px;
                    }

                    .title {
                        margin-bottom: 20px;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                padding: 55px 0;
            }
        }

        .investment-return {
            //padding: 70px 0 150px;
            padding: 70px 0;

            .image {
                height: 307px;
                max-width: 400px;
                width: 100%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;

                @include media-breakpoint-down(sm) {
                    height: 251px;
                    margin-bottom: 35px;
                }
            }

            .description {
                padding-left: 30px;

                .title {
                    margin-bottom: 20px;
                }

                .text {
                    margin-bottom: 30px;
                }

                @include media-breakpoint-down(sm) {
                    padding-left: 0;
                }
            }

            @include media-breakpoint-down(sm) {
                //padding: 20px 0 135px;
                padding: 20px 0;
            }
        }

        .districts-return {
            background-color: $color-2;
            text-align: center;

            display: none;

            .baloon-item {
                background-color: $color-1;
                padding: 40px 15px 30px;
                border-radius: 10px;
                position: relative;
                margin-top: -75px;
                margin-bottom: 80px;

                &:after {
                    content: '';
                    display: block;
                    width: 40px;
                    height: 40px;
                    transform: rotate(45deg);
                    background-color: $color-1;
                    position: absolute;
                    bottom: -20px;
                    left: 50%;
                    margin-left: -20px;
                }

                .title, .text {
                    color: $color-2;
                }

                @include media-breakpoint-down(sm) {
                    margin-bottom: 60px;
                }
            }

            .districts {
                .item {
                    padding-bottom: 30px;

                    .image {
                        margin-bottom: 20px;
                    }
                }   
            }

            .action {
                margin-bottom: 40px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 80px;
                }
            }

            .title, .text {
                color: $color-0;
            }
        }

        .benefits {
            padding: 90px 0 80px;
            text-align: center;

            @include media-breakpoint-down(sm) {
                padding: 55px 0 10px;
            }

            .block-title {
                margin-bottom: 40px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 25px;
                }
            }

            .topics {
                .topic {
                    .icon {
                        margin-bottom: 25px;
                    }

                    .title {
                        margin-bottom: 20px;
                    }

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 50px;
                    }
                }
            }
        }

        .benefits-2 {
            background-color: $color-1;
            padding: 80px 0;

            @include media-breakpoint-down(sm) {
                padding: 60px 0 40px;
            }

            .logo {
                margin-bottom: 50px;

                @include media-breakpoint-down(sm) {
                    display: block;
                    margin: 0 auto 30px;
                }
            }

            .block-title {
                @include media-breakpoint-down(sm) {
                    text-align: center;
                    padding: 0 30px 20px;                    
                }
            }

            .benefits-list {
                .item {
                    padding: 0 0 0 35px;
                    max-width: 300px;
                    position: relative;

                    @include media-breakpoint-down(sm) {
                        padding: 0 0 10px 35px;
                    }

                    &:before {
                        content: '';
                        width: 15px;
                        height: 2px;
                        display: block;
                        background-color: $color-0;
                        position: absolute;
                        top: 11px;
                        left: 0;
                    }

                    &:after {
                        content: '';
                        width: 10px;
                        height: 10px;
                        display: block;
                        background-color: transparent;
                        border-top: 2px solid $color-0;
                        border-right: 2px solid $color-0;
                        position: absolute;
                        top: 7px;
                        left: 6px;
                        transform: rotate(45deg);
                    }
                }

                @include media-breakpoint-down(sm) {
                    padding-left: 15px;
                }
            }
        }

        .calculation-info {
            .block-title {
                margin-bottom: 30px;
            }

            .steps {
                .item {
                    position: relative;
                    padding: 0 0 10px 75px;

                    .step {
                        font-size: 26px;
                        color: $color-0;
                        width: 50px;
                        height: 50px;
                        background-color: $color-2;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }    
                }

                @include media-breakpoint-down(sm) {
                    margin-bottom: 15px;
                }
            }
        }

        .calculation {
            padding: 90px 0 100px;

            @include media-breakpoint-down(sm) {
                padding: 45px 0 50px;
            }

            .block-title {
                margin-bottom: 30px;
            }

            .steps {
                .item {
                    position: relative;
                    padding: 0 0 10px 75px;

                    .step {
                        font-size: 26px;
                        color: $color-0;
                        width: 50px;
                        height: 50px;
                        background-color: $color-2;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }    
                }

                @include media-breakpoint-down(sm) {
                    margin-bottom: 15px;
                }
            }

            .block-form {
                .form {
                    .block-group {
                        margin-bottom: 15px;
                    }

                    .block-check {
                        margin-top: 20px;
                        margin-bottom: 30px;

                        .text {
                            margin: 0 0 5px 20px;
                        }

                        .group {
                            border: 1px solid $color-3;
                            border-radius: 10px;
                            position: relative;

                            .custom-control-label {
                                @include media-breakpoint-down(sm) {
                                    font-size: 15px;
                                    padding: 15px 10px;
                                }
                            }
                        }
                    }

                    .btn {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }

    .result-calculation {
        width: 100%;
        border-radius: 10px;
        background: #FFD300;
        padding: 40px 50px;
        border: 1px solid #FFD300;
        background: #FFF;
        box-shadow: 0px 0px 60px 0px rgba(255, 211, 0, 0.20);

        @media (max-width: 767px) { 
            padding: 30px;
        }

        .txt1,
        .txt2,
        .txt-result,
        .txt-info {
            display: block;
            color: #000;
        }

        .txt1 {
            font-size: 18px;
            font-weight: 600;
            margin: 0 0 5px;

            @media (max-width: 767px) {
                font-size: 16px;
            }

            svg {
                display: inline-block;
                margin: 0 10px 2px 0;
                vertical-align: text-top;
            }
        }

        .txt2 {
            font-size: 16px;
            font-weight: 400; 
            margin: 0 0 15px;

            @media (max-width: 767px) {
                font-size: 12px;

                br {
                    display: none;
                }
            }
        }

        .txt-result {
            font-size: 14px;
            font-weight: 400;

            strong {
                font-size: 20px;
                font-weight: 600;
            }
        }

        .txt-info {
            margin: 24px 0 0;
            font-size: 12px;
            font-weight: 400;

            strong {
                font-weight: 600;
            }
        }

        .buttons {
            margin: 25px 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 991px) {
                flex-direction: column;
                justify-content: center;
            }

            .btn-c {
                border-radius: 10px;
                height: 70px;
                width: 48%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #1A1818;
                text-align: center;
                font-size: 18px;
                font-weight: 400;

                @media (max-width: 1199px) {
                    font-size: 16px;
                }

                @media (max-width: 991px) {
                    width: 100%;
                }

                @media (max-width: 767px) { 
                    height: 50px;
                    font-size: 14px;
                }

                svg {
                    display: block;
                    margin: 0 10px 2px 0;
                }
            }

            .btn-c1 {
                background: #fff;
                border: 1px solid #1A1818;

                @media (max-width: 991px) {
                    margin-top: 10px;
                }
            }

            .btn-c2 { 
                background: #FFD300;
                border: 1px solid #FFD300;

                svg {
                    display: block;
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
    
                    @media (max-width: 767px) {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        .btn-link {
            display: flex;
            text-decoration: underline;
            width: 296px;
            margin: 24px auto 0;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #1a1818;

            @media (max-width: 767px) {
                width: 100%;
                font-size: 12px;
            }

            svg {
                display: block;
                width: 20px;
                height: 20px;
                margin-right: 10px;

                @media (max-width: 767px) {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    &.result {
        .page-header {
            padding: 90px 0 160px;

            @include media-breakpoint-down(sm) {
                padding: 90px 0 105px;
            }

            .title {
                margin-bottom: 30px;

                &.title-3 {
                    color: $color-0;
                }   
            }

            .prices {
                font-family: $poppinssemibold;
                font-size: 40px;
                line-height: 40px;
                color: $color-1;
                margin-bottom: 30px;

                small {
                    font-size: 18px;
                }

                br {
                    display: none;

                    @include media-breakpoint-down(sm) {
                        display: block;
                    }
                }
            }

            .action {
                display: flex;

                .btn {
                    margin-right: 20px;

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 10px;
                    }
                }

                @include media-breakpoint-down(sm) {
                    display: block;
                }
            }
        }

        .content {
            padding: 100px 0;

            .result-info {
                .description {
                    .title-2 {
                        margin-bottom: 50px;
                    }

                    .steps {
                        margin-bottom: 80px;
                        
                        .item {
                            position: relative;
                            padding: 0 0 10px 75px;
        
                            .step {
                                font-size: 26px;
                                color: $color-0;
                                width: 50px;
                                height: 50px;
                                background-color: $color-2;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }  
                            
                            .title-3 {
                                margin-bottom: 30px;

                                &.yellow {
                                    color: $color-10;
                                }

                                &.red {
                                    color: $color-8;
                                }
                            }
                            
                            .values {
                                display: flex;
    
                                @include media-breakpoint-down(sm) {
                                    flex-wrap: wrap;
                                }

                                .block {
                                    max-width: 180px;

                                    @include media-breakpoint-down(sm) {
                                        width: 50%;
                                        max-width: 50%;
                                    }

                                    .text {
                                        padding-right: 30px;
                                    }

                                    .title {
                                        white-space: nowrap;

                                        &.title-1 {
                                            color: $color-10;
                                        }

                                        span {
                                            margin: 0 20px 0 15px;
                                        }
                                    }
    
                                    &:last-child {
                                        max-width: 190px;

                                        @include media-breakpoint-down(sm) {
                                            width: 100%;
                                            max-width: 100%;
                                            text-align: center;
                                        }
                                    }
                                }

                                @include media-breakpoint-down(sm) {
                                    margin-left: -75px;
                                }
                            }
                        }

                        @include media-breakpoint-down(sm) {
                            margin-bottom: 35px;
                        }
                    }
                }

                .block-actions {
                    text-align: center;
                    box-shadow: 0px 3px 6px #0000001A;
                    -webkit-box-shadow: 0px 3px 6px #0000001A;
                    -moz-box-shadow: 0px 3px 6px #0000001A;
                    padding: 35px;
                    border-radius: 10px;

                    .btn {
                        display: block;
                        margin-bottom: 15px;
                    }

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 50px;
                    }
                }
            }
        }
    }

    &.how-modality {
        .page-header {
            padding: 130px 0 110px;
        }

        .content {
            padding: 80px 0;

            @include media-breakpoint-down(sm) {
                padding: 55px 0;
            }

            .buy-info {
                .description {
                    text-align: center;
                    margin-bottom: 40px;

                    .text {
                        margin-bottom: 25px;

                        a {
                            font-size: 15px;
                            color: $color-10;
                        }
                    }

                    .btn-1 {
                        @include media-breakpoint-down(sm) {
                            max-width: 326px;
                            width: 100%;
                        }
                    }
                }

                .steps {
                    margin-bottom: 80px;

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 0;
                    }
                    
                    .item {
                        position: relative;
                        padding: 0 50px 40px 75px;
                        display: flex;
                        align-items: center;

                        @include media-breakpoint-down(sm) {
                            padding: 0 0 40px 75px;
                        }

                        .text {
                            margin: 0;
                        }

                        .step {
                            font-size: 26px;
                            color: $color-10;
                            width: 60px;
                            height: 60px;
                            background-color: $color-0;
                            border: 2px solid $color-10;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }  
                    }
                }

                .specific-info {
                    .image {
                        @include media-breakpoint-down(sm) {
                            margin: 0 auto 30px;
                        }
                    }

                    .info {
                        margin-left: 30px;
                        margin-bottom: 40px;

                        @include media-breakpoint-down(sm) {
                            margin-left: 0;
                        }
                    }

                    .block-title {
                        text-align: center;
                        margin-top: 60px;
                        margin-bottom: 20px;

                        @include media-breakpoint-down(sm) {
                            margin-top: 35px;
                        }

                        .title {
                            color: $color-10;
                        }
                    }
                }
            }

            .buy-options {
                .info {
                    padding-left: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;

                    @include media-breakpoint-down(sm) {
                        padding-left: 0;
                        text-align: center;
                    }

                    .title {
                        margin-bottom: 20px;
                    }

                    .text {
                        margin-bottom: 30px;
                    }
                }

                .how-it-works {
                    padding: 75px 0 0;

                    .block-title {
                        text-align: center;
                        margin-bottom: 45px;
                    }

                    .item {
                        box-shadow: -1px 3px 14px -3px #0000001A;
                        -webkit-box-shadow: -1px 3px 14px -3px #0000001A;
                        -moz-box-shadow: -1px 3px 14px -3px #0000001A;
                        background-color: $color-0;
                        padding: 50px 60px 50px 90px;
                        border-radius: 10px;
            
                        @include media-breakpoint-down(sm) {
                            margin-bottom: 55px;
                        }

                        .step {
                            font-size: 30px;
                            color: $color-0;
                            background-color: $color-2;
                            border-radius: 50%;
                            width: 50px;
                            height: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }

                &.financing-block {
                    background-color: $color-4;
                    padding: 75px 0 90px;

                    @include media-breakpoint-down(sm) {
                        padding: 15px 0 0;
                    }

                    .image {
                        @include media-breakpoint-down(sm) {
                            margin-bottom: 30px;
                        }
                    }
                                            
                    .how-it-works {
                        padding: 75px 0 0;
    
                        .block-title {
                            text-align: center;
                            margin-bottom: 45px;
                        }
    
                        .item {
                            box-shadow: -1px 3px 14px -3px #0000001A;
                            -webkit-box-shadow: -1px 3px 14px -3px #0000001A;
                            -moz-box-shadow: -1px 3px 14px -3px #0000001A;
                            background-color: $color-0;
                            position: relative;
                            height: 180px;
                            padding: 50px 60px 50px 90px;
                            border-radius: 10px;
                
                            .step {
                                font-size: 30px;
                                color: $color-0;
                                background-color: $color-2;
                                border-radius: 50%;
                                width: 50px;
                                height: 50px;
                                position: absolute;
                                top: -25px;
                                left: 50%;
                                margin-left: -25px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }

                &.consortium-block {
                    padding: 75px 0 40px;

                    @include media-breakpoint-down(sm) {
                        padding: 50px 0 0;
                    }

                    .image {
                        @include media-breakpoint-down(sm) {
                            margin-bottom: 30px;
                        }
                    }

                    .how-it-works {
                        padding: 75px 0 0;
    
                        .block-title {
                            text-align: center;
                            margin-bottom: 45px;
                        }
    
                        .item {
                            position: relative;
                            padding: 50px 60px 0 70px;
                            height: 300px;
                            margin-bottom: 30px;

                            @include media-breakpoint-down(sm) {
                                padding: 50px 15px;
                                height: auto;
                                text-align: center;
                                margin-bottom: 50px;
                            }
                
                            .step {
                                font-size: 30px;
                                color: $color-0;
                                background-color: $color-2;
                                border-radius: 50%;
                                width: 50px;
                                height: 50px;
                                position: absolute;
                                top: -12px;
                                left: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                @include media-breakpoint-down(sm) {
                                    top: -75px;
                                    left: 50%;
                                    margin-left: -25px;
                                }
                            }
                
                            .title {
                                position: relative;
                                color: $color-5;
                                margin-bottom: 30px;
                                padding-left: 60px;

                                @include media-breakpoint-down(sm) {
                                    padding-left: 0;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }
            }

            .banner {
                .item {
                    height: 250px;
                    width: 100%;
                    padding: 60px 0 40px 70px;
                    background-position: right;
                    background-repeat: no-repeat;
                    border-radius: 10px;
                    background-color: $color-1;

                    @include media-breakpoint-down(sm) {
                        height: 542px;
                        padding: 60px 0 0;
                        text-align: center;
                    }

                    &.show-mobile {
                        @include media-breakpoint-down(sm) {
                            display: flex;
                            align-items: flex-end;
                        }
                    }
                    
                    .block-info {
                        display: flex;
                        align-items: center;
                        height: 100%;

                        @include media-breakpoint-down(sm) {
                            padding: 0 30px;
                        }

                        &.center {
                            text-align: center;

                            @include media-breakpoint-down(sm) {
                                height: 310px;
                                border-radius: 10px;
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: cover;
                            }
                        }

                        .block {
                            .title {
                                margin-bottom: 0;
                                font-size: 30px;
                                line-height: 35px;

                                @include media-breakpoint-down(sm) {
                                    margin-bottom: 40px;
                                }
                            }
        
                            .text {
                                color: $color-0;
                            }
                        }
                    }
                }
            }
        }

        .simple-banner {
            margin-bottom: -70px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 50px;
            }

            .item {
                padding: 30px 60px;
                width: 100%;
                height: 140px;
                background-color: $color-5;
                border-radius: 10px;

                @include media-breakpoint-down(sm) {
                    padding: 35px 10px 45px;
                    height: auto;
                    text-align: center;
                }

                .title {
                    font-size: 20px;
                    line-height: 30px;
                    color: $color-0;
                    margin-bottom: 0;

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 20px;
                        padding: 0 15px;
                    }
                }
            }
        }

        .box-info {
            padding: 120px 0 80px;

            @include media-breakpoint-down(sm) {
                padding: 0 0 80px;
            }

            .block-title {
                text-align: center;
                margin-bottom: 35px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 0;
                }

                .title-2 {
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 5px;
                    }
                }
            }

            &.box-gray {
                background-color: $color-4;
            }

            .box {
                background-color: $color-0;   
                border-radius: 10px; 
                padding: 70px 90px;
                box-shadow: -1px 3px 14px -3px #0000001A;
                -webkit-box-shadow: -1px 3px 14px -3px #0000001A;
                -moz-box-shadow: -1px 3px 14px -3px #0000001A;

                @include media-breakpoint-down(sm) {
                    padding: 45px 30px 15px;
                }

                .block-tabs {
                    .MuiPaper-root {
                        max-width: 100%;
                        border: none;

                        @include media-breakpoint-down(sm) {
                            margin-bottom: 5px;
                        }

                        .MuiTabs-flexContainer {
                            @include media-breakpoint-down(md) {
                                flex-wrap: wrap;
                            }
                        }

                        .MuiButtonBase-root {
                            border: 1px solid $color-2;
                            background-color: transparent;

                            @include media-breakpoint-down(sm) {
                                font-size: 14px;
                                width: 100%;
                                min-width: 200px;
                            }

                            &.Mui-selected {
                                color: $color-1;
                                background-color: $color-2;
                            }
                        }
                    }

                    .info {
                        @include media-breakpoint-down(sm) {
                            margin: 0 -20px;
                        }

                        .title-6 {
                            margin-bottom: 30px;
                        }

                        .text {
                            margin-bottom: 25px;
                        }

                        .btn {
                            margin-right: 15px;
                        }

                        .steps {
                            .step {
                                position: relative;
                                padding-left: 40px;

                                .icon {
                                    height: 24px;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                }       
                            }
                        }   
                        
                        &.first {
                            max-width: 415px;

                            @include media-breakpoint-down(sm) {
                                margin-bottom: 55px;
                            }
                        }
                    }
                }
            }

            .form {
                .text {
                    &.list {
                        position: relative;
                        padding-left: 30px;

                        span {
                            display: block;
                            width: 10px;
                            height: 10px;
                            background-color: $color-2;
                            border-radius: 50%;
                            position: absolute;
                            top: 7px;
                            left: 0;
                        }
                    }
                }

                .btn {
                    width: 100%;
                }

                @include media-breakpoint-down(sm) {
                    margin-bottom: 35px;
                }
            }
        }
    }
}

.simulate-financing {
    padding: 100px 0 120px;

    @include media-breakpoint-down(sm) {
        padding: 70px 0;
    }

    .block-title {
        @include media-breakpoint-down(sm) {
            margin-bottom: 25px;
        }
    }

    .action {
        text-align: right;

        @include media-breakpoint-down(sm) {
            text-align: left;
            margin-bottom: 10px;
        }

        .btn {
            position: relative;

            .icon {
                position: absolute;
                top: 20px;
                left: 25px;

                &:before {
                    content: '';
                    display: block;
                    width: 7px;
                    height: 7px;
                    background-color: transparent;
                    border-bottom: 1px solid $color-2;
                    border-left: 1px solid $color-2;
                    transform: rotate(45deg);
                }

                &:after {
                    content: '';
                    display: block;
                    width: 15px;
                    height: 1px;
                    background-color: $color-2;
                    margin-top: -4px;
                }
            }
        }
    }

    .block-form {
        .block-check {
            position: relative;
            margin: 40px 0;

            .group {
                display: flex;
            }

            .custom-control {
                margin-right: 10px;
                width: 100%;

                .custom-control-label {
                    padding: 10px 30px;
                    color: $color-7;
                    border: 1px solid $color-7;
                    border-radius: 10px;

                    @include media-breakpoint-down(sm) {
                        font-size: 15px;
                        padding: 10px;
                    }
                }

                .custom-control-input:checked ~ .custom-control-label {
                    color: $color-0;
                    border: none;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .btn-1 {
            margin-top: 20px;

            @include media-breakpoint-down(sm) {
                max-width: 295px;
                width: 100%;
            }
        }
    }
}

.property-content {
    .media-content {
        margin-bottom: 45px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }

        .MuiBox-root {
            padding: 0;
            margin-bottom: 30px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 10px;
            }
        }

        .MuiTabs-flexContainer {
            display: inherit;
            padding: 10px 0;

            @include media-breakpoint-down(sm) {
                white-space: normal;
            }
        }

        .MuiPaper-root {
            max-width: 750px;
            margin: 0;
            border: none;

            .MuiTab-root {
                min-width: inherit;
                color: $color-7;
                font-size: 14px;
                line-height: 12px;
                border-radius: 5px;
                background-color: transparent;
                border: 1px solid $color-7;
                height: 35px;
                padding: 5px 15px 5px 55px;
                margin: 0 10px;
                background-position: 20px center;
                overflow: inherit;

                @include media-breakpoint-down(sm) {
                    margin: 5px;
                }

                &.photo {
                    background-image: url(../../images/icons/insert-picture-icon.svg);
                    background-size: 20px;
                    background-repeat: no-repeat;

                    &.Mui-selected {
                        background-image: url(../../images/icons/insert-picture-icon-white.svg);
                    }
                }

                &.video {
                    background-image: url(../../images/icons/icon-video.svg);
                    background-size: 20px;
                    background-repeat: no-repeat;

                    &.Mui-selected {
                        background-image: url(../../images/icons/video-camera.svg);
                    }
                }

                &.tour {
                    background-image: url(../../images/icons/icon-tour-virtual.svg);
                    background-size: 20px;
                    background-repeat: no-repeat;

                    &.Mui-selected {
                        background-image: url(../../images/icons/360-degrees.svg);
                    }
                }

                &.map {
                    background-image: url(../../images/icons/icon-mapa.svg);
                    background-size: 20px;
                    background-repeat: no-repeat;

                    &.Mui-selected {
                        background-image: url(../../images/icons/map.svg);
                    }
                }

                &.street {
                    background-image: url(../../images/icons/earth-cycles.svg);
                    background-size: 20px;
                    background-repeat: no-repeat;

                    &.Mui-selected {
                        background-image: url(../../images/icons/earth-cycles-white.svg);
                    }
                }

                &.Mui-selected {
                    color: $color-0;
                    background-color: $color-2;
                    border-color: $color-2;
                    position: relative;

                    &:after {
                        content: '';
                        display: block;
                        width: 0; 
                        height: 0; 
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 7px solid $color-2;
                        position: absolute;
                        top: -8px;
                        left: 18px;
                    }
                }
            }
        }

        .property-gallery {
            display: flex;
            
            .swiper-slide {
                height: 220px !important;
                width: auto !important;

                &:first-child {
                    height: 445px !important;
                    width: auto !important;

                    @include media-breakpoint-down(sm) {
                        height: 300px !important;
                        width: 360px !important;
                    }

                    .image-item {
                        width: 558px !important;
                    }

                    .image {
                        height: 445px !important;
                        width: 558px !important;

                        @include media-breakpoint-down(sm) {
                            height: 300px !important;
                            width: 360px !important;
                        }
                    }
                }

                @include media-breakpoint-down(sm) {
                    height: 300px !important;
                    width: 730px !important;
                }
            }

            .image-item {
                height: 220px;
                width: 399px;

                img {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }
                
                .image {
                    display: block;
                    height: 220px;
                    width: 399px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    margin-bottom: 5px;
                    position: relative;
                    overflow: hidden;

                    &:nth-child(2) {
                        @include media-breakpoint-down(sm) {
                            margin-left: 5px;
                        }
                    }

                    @include media-breakpoint-down(sm) {
                        height: 300px;
                        width: 100%;
                    }
                }

                @include media-breakpoint-down(sm) {
                    height: 300px !important;
                    width: 730px !important;
                    display: flex;
                }
            }
    
            .swiper-button-prev, .swiper-button-next {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: $color-0;
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
    
                &.swiper-button-disabled {
                    &:after {
                        border-color: $color-3;
                    }
                }
    
                &:after {
                    content: '';
                    display: block;
                    width: 10px;
                    height: 10px;
                    background-color: transparent;
                    border-top: 2px solid $color-2;
                    border-right: 2px solid $color-2;
                    // border-radius: 2px;                
                }
            }
    
            .swiper-button-prev {
                left: 30px;
    
                &:after {
                    transform: rotate(-135deg);
                }
            }
    
            .swiper-button-next {
                right: 30px;
                
                &:after {
                    transform: rotate(40deg);
                }
            }
        }

        .property-video {
            background-color: $color-1;

            .player-item {
                margin: 0 auto;
            }
        }

        .action-favorite {
            text-align: right;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        .property-map {
            position: relative;

            .form {
                position: absolute;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                bottom: 15px;
                right: 60px;
                background: #FFF;
                padding: 10px;
                border-radius: 15px;


                .text {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                .form-group {
                    margin: 0 10px;
                    max-width: 330px;
                    width: 100%;

                    @include media-breakpoint-down(sm) {
                        margin-right: 10px;
                    }

                    .form-control {
                        width: 100%;
                    }
                }
            }
        }
    }

    .black-band {
        background-color: $color-2;
        padding: 10px 0;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        .block-info {
            display: flex;
            
            .text-interest {
                font-size: 12px;
                color: $color-0;
            }

            .list {
                list-style: none;
                display: flex;
                margin: 0;

                li {
                    font-size: 14px;
                    color: $color-1;
                    margin-right: 50px;

                    @media (max-width: 991px) {
                        font-size: 12px;
                        margin-right: 20px;
                    }

                    .icon {
                        margin-right: 7px;
                    }
                }

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            .info {
                .title, .text {
                    color: $color-0;
                    margin: 0;

                    &.title-yellow {
                        color: $color-1;
                    }
                }

                @media (max-width: 991px) {
                    .title.title-4 {
                        font-size: 14px;
                    }

                    .title.title-5 {
                        font-size: 12px;
                    }
                }

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            .btn-1 {
                margin: 0 15px;
                max-width: 270px;
                width: 100%;

                .icon {
                    height: 15px;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }

            .btn {
                @include media-breakpoint-down(sm) {
                    padding: 7px 25px;
                    margin-left: 0;
                }
            }

            @include media-breakpoint-down(sm) {
                justify-content: center;
            }
        }

        .new-whatsapp,
        .new-visit2 {
            padding: 0 16px;
            font-size: 16px;
            height: 50px;

            @media (max-width: 1199px) {
                width: auto;
                font-size: 14px;
            }

            @media (max-width: 991px) {
                font-size: 12px;

                svg {
                    display: none;
                }
            }
        }

        .new-visit2 {
            margin-left: 10px;
        }

    }

    .content {
        padding-bottom: 65px;

        @include media-breakpoint-down(sm) {
            padding-bottom: 0;
        }
        
        .description {
            padding-right: 25px;

            @include media-breakpoint-down(sm) {
                padding: 0;
            }
        }

        .details {
            padding: 30px 0;

            .name {
                margin-bottom: 30px;
            }

            .location {
                color: $color-1;
            }
        }

        .highlights {
            display: flex;
            max-width: 550px;
            width: 100%;
            margin-bottom: 80px;
            flex-wrap: wrap;

            @include media-breakpoint-down(sm) {
                margin-right: -15px;
                margin-bottom: 35px;
                width: auto;
            }

            .highlight {
                background-color: $color-4;
                width: calc(100% / 2 - 15px);
                border-radius: 5px;
                padding: 20px;
                margin: 0 15px 15px 0;

                .icon {
                    margin-right: 20px;
                }
            }
        }

        .info {
            margin-bottom: 80px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 50px;
            }

            .block-text {
                margin-bottom: 50px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 10px;
                }
            }

            .title {
                position: relative;
                padding-left: 20px;
                margin-bottom: 25px;

                &:after {
                    content: '';
                    display: block;
                    width: 0; 
                    height: 0; 
                    border-top: 9px solid transparent;
                    border-bottom: 9px solid transparent;
                    border-left: 9px solid $color-1;
                    position: absolute;
                    top: 5px;
                    left: 0;
                }
            }

            .features {
                display: flex;
                max-width: 550px;
                width: 100%;
                flex-wrap: wrap;

                @include media-breakpoint-down(sm) {
                    max-width: inherit;
                    width: auto;
                    margin-right: -15px;
                }
    
                .feature {
                    background-color: $color-4;
                    // max-width: 170px;
                    width: calc(100% / 3 - 15px);
                    border-radius: 5px;
                    padding: 10px 25px;
                    margin: 0 15px 15px 0;
                    display: flex;
                    height: 70px;
                    align-items: center;

                    @include media-breakpoint-down(sm) {
                        width: calc(100% / 2 - 15px);
                    }
    
                    .text {
                        margin: 0;
                    }
                }
            }

            .form {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;

                .text {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                .form-group {
                    margin: 0;
                    max-width: 330px;
                    width: 100%;

                    @include media-breakpoint-down(sm) {
                        margin-right: 10px;
                    }

                    .form-control {
                        width: 100%;
                    }
                }
            }

            .map-block {
                .text-1 {
                    text-align: center;

                    @include media-breakpoint-down(sm) {
                        font-size: 12px;
                    }
                }

                .map {
                    height: 250px;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    overflow: hidden;
                    position: relative;

                    .btn {
                        .icon {
                            height: 18px;
                            margin-right: 10px;
                        }
                    }
                }

                .locals-list {
                    display: flex;
                    flex-wrap: wrap;
                    
                    .custom-control {
                        width: calc(100% / 3);
                        font-size: 14px;
                        line-height: 25px;

                        @include media-breakpoint-down(sm) {
                            width: calc(100% / 2);
                        }
    
                        .custom-control-label {
                            &:before {
                                background-color: $color-0;
                                border: 1px solid $color-3;
                                border-radius: 0;
                            }
                        }
    
                        .custom-control-input:checked ~ .custom-control-label::before {
                            border: 1px solid $color-1;
                        }
    
                        .custom-control-input:checked ~ .custom-control-label::after {
                            content: '';
                            display: block;
                            width: 7px;
                            height: 7px;
                            background-color: transparent;
                            border-bottom: 1px solid $color-1;
                            border-right: 1px solid $color-1;
                            transform: rotate(45deg);
                            position: absolute;
                            left: -20px;
                            top: 6px;
                        }
    
                        .custom-control-input:focus ~ .custom-control-label::before {
                            box-shadow: 0 0 0 1px $color-0, 0 0 0 0.2rem rgba(255, 211, 0, 0.25);
                        }
                    }
                }
            }
        }

        .small-banner {
            max-width: 630px;
            width: 100%;
            height: 110px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    .property-list {
        padding: 80px 0 95px;

        &.gray {
            background-color: $color-4;
        }

        .block-title {
            text-align: center;
            margin-bottom: 30px;
        }

        > .swiper-container {
            padding: 5px 0;
    
            .swiper-button-next,
            .swiper-button-prev {
                width: 30px;
                height: 30px;
                border-radius: 10px;
                border: 2px solid #939393;
    
                &:after {
                    content: '';
                    display: block;
                    width: 10px;
                    height: 10px;
                    background-color: transparent;
                    border-top: 2px solid #939393;
                    border-right: 2px solid #939393;
                    top: 50%;
                    margin-top: -5px;
                    left: 50%;
                    margin-left: -7px;
                    position: absolute;
                }
            }
    
            .swiper-button-next {
                &:after {
                    transform: rotate(45deg);
                }
            }
    
            .swiper-button-prev {
                &:after {
                    transform: rotate(-135deg);
                    margin-left: -4px;
                }
            }
        }
    }

    .additional-info {
        background-color: $color-4;
        padding: 65px 0 95px;

        @include media-breakpoint-down(sm) {
            padding: 50px 0 25px;
        }

        .image {
            border-radius: 10px;

            @include media-breakpoint-down(sm) {
                margin: 0 auto;
            }
        }

        .description {
            padding: 35px 0;

            .title {
                position: relative;
                padding-left: 20px;
                margin-bottom: 30px;
    
                &:after {
                    content: '';
                    display: block;
                    width: 0; 
                    height: 0; 
                    border-top: 9px solid transparent;
                    border-bottom: 9px solid transparent;
                    border-left: 9px solid $color-1;
                    position: absolute;
                    top: 5px;
                    left: 0;
                }
            }
        }
    }

    .sidebar {
        box-shadow: 0px 3px 6px #0000001A;
        -webkit-box-shadow: 0px 3px 6px #0000001A;
        -moz-box-shadow: 0px 3px 6px #0000001A;
        position: relative;
        padding: 40px;
        border-radius: 10px;
        margin-bottom: 30px;
        border: 1px solid #F3F3F3;

        @include media-breakpoint-down(sm) {
            padding: 40px 25px;
        }

        .block-info {
            margin-bottom: 30px;

            .property-type {
                border-bottom: 1px solid #EEE;
                padding-bottom: 10px;
            }

            .price {
                display: block;
                line-height: 1em;
            }

            .priceOld {
                display: block;
                line-height: 1.5em;
                font-size: 18px;
                text-decoration: line-through;
                color: #A5A5A5;
            }

            > .text-1 {
                color: $color-6;
            }

            .text-1 {
                margin: 0;
            }
        }

        .block-actions {
            .btn {
                margin-bottom: 20px;
                width: 100%;

                &.btn-3 {
                    .icon {
                        height: 12px;
                    }
                }

                .icon {
                    height: 15px;
                    margin-right: 10px;
                }
            }

            .form-more-info {
                position: absolute;
                top: 130px;
                background-color: $color-0;
                max-width: 280px;
                height: 0;

                .form {
                    background-color: $color-0;
                    padding-top: 30px;
                }

                .btn {
                    margin-right: 0;
                    margin-left: 0;
                }

                .MuiSwitch-root {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }
            }

            .block-actions-secundary {
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media (max-width: 991px) and (min-width:768px) {
                    flex-direction: column;
                }

                .new-link-phone {
                    svg {
                        width: 14px;
                        height: 14px;
                        fill: #1A1818;
                        margin: 0 6px 2px 0;
                    }

                    &.active {
                        background: #F5F5F5;
                        position: relative;

                        &:before {
                            content: '';
                            background: #F5F5F5;
                            position: absolute;
                            top: -5px;
                            left: -15px;
                            height: calc(100% + 5px);
                            width: calc(100% + 30px);
                            border-radius: 10px 10px 0px 0px;
                            display: block;
                            z-index: -1;
                        }
                    }
                }

                .new-link-phone,
                .new-link-more {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: center;
                    color: #1A1818;
                    text-decoration: underline;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px 0;
                    border: 0;
                    background: transparent;
                    outline: none;
                    cursor: pointer;

                    > a {
                        font-size: 13px;
                    }
                }
            }

            .phones-box {
                background: #F5F5F5;
                border-radius: 0px 10px 10px 10px;
                padding: 20px;
                width: calc(100% + 15px);
                margin-left: -15px;

                p {
                    color: #1A1818;
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 400;
                    margin: 0;

                    a {
                        font-size: 12px;
                    }

                    strong {
                        font-weight: 700;
                        font-size: 12px;
                    }

                    + p {
                        margin-top: 14px;
                    }
                }
            }
        }

        .btn-voltar {
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                display: block;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADgSURBVHgBndAxDsIgFAbghx2LSYcmQLtwBL2Bbo7eQN0cHd08gt5Ar+DopJ6g3sBOhgO0Jh0qPho10UiLvqQBXn++AIRzPmoB9C5KTeCPwv1rHA5em9IVARhS3w+yPN/9ggghlniIKdFaemEYbsuyHBjMpxSyLDu6IHiSBSJz0Pp0LYp+1ZRSBpEQScS5NgEXxGQjxpIA6+1nhXF+bsJeCGZxLm0hWYc5Id8wxtjMFSE2DB9yb258AxibHq43OKS47iulUifoE3u0rEgt9MQ8QhIzL7Xu2hCniuO4Y76m3B26TlMZe0hC2QAAAABJRU5ErkJggg==);
                background-size: auto;
                background-position: center;
                width: 18px;
                height: 11px;
                transform: rotate(90deg);
                margin: 0 10px 0 0;
            }
        }

        .form {
            

            .form-group {
                margin-bottom: 10px;
            }

            .form-control {
                height: 70px;
                padding: 25px 20px 5px;
                appearance: none;

                &.custom-select {
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADgSURBVHgBndAxDsIgFAbghx2LSYcmQLtwBL2Bbo7eQN0cHd08gt5Ar+DopJ6g3sBOhgO0Jh0qPho10UiLvqQBXn++AIRzPmoB9C5KTeCPwv1rHA5em9IVARhS3w+yPN/9ggghlniIKdFaemEYbsuyHBjMpxSyLDu6IHiSBSJz0Pp0LYp+1ZRSBpEQScS5NgEXxGQjxpIA6+1nhXF+bsJeCGZxLm0hWYc5Id8wxtjMFSE2DB9yb258AxibHq43OKS47iulUifoE3u0rEgt9MQ8QhIzL7Xu2hCniuO4Y76m3B26TlMZe0hC2QAAAABJRU5ErkJggg==);
                    background-size: auto;
                    background-position: top 36px right 20px
                }

                &::placeholder {
                    font-size: 14px;
                    line-height: 14px;
                    color: #1a1818;
                }
            }

            .error-message {
                bottom: -8px;
                left: 20px;
                z-index: 1;
                background: #fff;
            }
        }
    }

    .shared-block {
        @include media-breakpoint-down(sm) {
            margin-bottom: 55px;
        }

        .shared {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 15px;

            .list {
                list-style: none;
                display: flex;
                margin: 0 15px;

                .icon-whatsapp {
                    height: 17px;
                    margin-right: 10px;
                }

                .icon {
                    height: 13px;
                }
            }
        }

        .text-7 {
            color: $color-7;
            text-align: right;
            margin-left: 5px;
        }
    }
}

.visit-dialog {
    text-align: center;

    .title-5 {
        position: absolute;
        top: -40px;
        left: 0;

        .title-8 {
            color: $color-5;
        }
    }
    
    > div {
        background-color: $color-0 !important;
    }

    .block-visit {
        max-width: 536px;
        width: 100%;
        background-color: $color-0;
        box-shadow: 0px 3px 6px #0000001A;
        border: 1px solid $color-4;
        padding: 90px 60px;
        border-radius: 10px;
        overflow: inherit;
        margin: 0 auto;
        position: relative;

        &.block-visit-3 {
            max-width: 900px;
        }
    }

    .MuiPaper-root {
        position: absolute;
        max-width: 900px;
        width: 100%;
        background-color: $color-0;
        box-shadow: none;
        border: 0;
        padding: 0;
        border-radius: 10px;
        overflow: inherit;
    }

    .MuiDialogTitle-root {
        padding: 0;

        .MuiButtonBase-root {
            color: $color-2;
            padding: 5px 15px 3px;
            border-radius: 25px;
            box-shadow: 0px 3px 6px #0000001A;
            top: -60px;
            right: 0;

            .MuiIconButton-label {
                .MuiSvgIcon-root {
                    width: 20px;
                    height: 20px;
                }
            }

            &:after {
                content: 'Fechar';
                font-size: 14px;
            }
        }
    }

    .MuiDialogContent-root {
        padding: 15px 75px 0;

        .btn {
            display: block;
            width: 100%;
        }
    }
    
    .swiper-container {
        margin-bottom: 20px;

        .swiper-slide {
            .btn {
                border: 1px solid $color-2;
                border-radius: 20px;
                background-color: transparent;

                .title, .text {
                    color: $color-2;
                }
            }
            
            &.swiper-slide-active {
                .btn {
                    border: 1px solid $color-4;

                    .title, .text {
                        color: $color-3;
                    }
                }
            }

            &.swiper-slide-next {
                .btn {
                    border: 1px solid $color-1;
                    background-color: $color-1;

                    .title, .text {
                        color: $color-0;
                    }
                }
            }
        }

        .normal-group {
            margin-bottom: 30px;

            .text {
                margin-bottom: 7px;
            }
        }
    }
}

.SRLStage {
    .SRLCloseButton, .SRLNextButton, .SRLPrevButton {
        border-radius: 50%;
        display: block;
    }
}