.title {
    color: $color-2;
    font-family: $poppinsregular;
    line-height: normal;

    &.title-1 {
        font-size: 30px;
        line-height: 35px;

        @include media-breakpoint-down(sm) {
            font-size: 25px;
            line-height: 38px;
        }
    }

    &.title-2 {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
            font-size: 20px;
        }
    }

    &.title-3 {
        font-size: 20px;
        line-height: 30px;

        @include media-breakpoint-down(sm) {
            font-size: 18px;
            line-height: 27px;
        }

        span {
            font-family: $poppinssemibold;
        }
    }

    &.title-4 {
        font-size: 18px;
        line-height: 27px;
    }

    &.title-5 {
        font-family: $poppinssemibold;
        font-size: 16px;
        line-height: 25px;
    }

    &.title-6 {
        font-family: $poppinssemibold;
        font-size: 20px;
        line-height: 30px;
    }

    &.title-7 {
        font-family: $poppinssemibold;
        font-size: 18px;
        line-height: 27px;
    }

    &.title-8 {
        font-size: 16px;
        line-height: 25px;
    }

    &.title-9 {
        font-family: $poppinsbold;
        font-size: 20px;
        line-height: 30px;
    }

    &.title-10 {
        font-family: $poppinssemibold;
        font-size: 35px;
        line-height: 40px;
        position: relative;

        &:after {
            content: '';
            display: block;
            width: 40px;
            height: 2px;
            background-color: $color-1;
            margin: 10px auto;
        }

        @include media-breakpoint-down(sm) {
            font-size: 25px;
        }
    }

    &.title-11 {
        font-family: $poppinssemibold;
        font-size: 25px;
        line-height: 38px;
    }

    &.title-12 {
        font-family: $poppinssemibold;
        font-size: 35px;
        line-height: 53px;
    }

    &.title-13 {
        font-family: $poppinsregular;
        font-size: 25px;
        line-height: 38px;
        margin: 0;
    }

    &.title-14 {
        font-size: 25px;
        line-height: 38px;
        position: relative;

        &:after {
            content: '';
            display: block;
            width: 170px;
            height: 2px;
            background-color: $color-1;
            margin: 10px 0;
        }
    }

    &.title-15 {
        font-family: $poppinsregular;
        font-size: 40px;
        line-height: 50px;
        margin: 0 0 30px;

        @media (max-width: 767px) {
            font-size: 22px;
            line-height: 32px;
            margin: 0 0 20px;
        }
    }
}
