.header {
    position: fixed;
    z-index: 999;
    background-color: $color-0;
    width: 100%;
    border-top: 2px solid $color-1;
    border-bottom: 1px solid #EEE;

    @include media-breakpoint-down(sm) {
        border-top: 5px solid $color-1;
    }

    .creci-header {
        position: absolute;
        left: 23px;
        bottom: 0px;
        transform-origin: bottom left;
        transform: rotate(-90deg);
        font-size: 9px;
        font-weight: bold;
        background: #f5f5f5;
        padding: 5px 10px;
    }

    .navbar {
        padding: 0;

        .MuiButtonBase-root {
            width: 30px;
            height: 30px;
            min-width: auto;
            position: relative;
            margin-right: 20px;

            @media (max-width: 1140px) and (min-width: 990px) {
                margin: 0;
            }

            .bar {
                background-color: $color-1;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                display: block;
                position: absolute;

                &.bar-1 {
                    top: 0;
                    left: 0;
                }

                &.bar-2 {
                    top: 0;
                    left: 50%;
                    margin-left: -3px;
                }

                &.bar-3 {
                    top: 0;
                    right: 0;
                }

                &.bar-4 {
                    top: 50%;
                    margin-top: -3px;
                    left: 0;
                }

                &.bar-5 {
                    top: 50%;
                    margin-top: -3px;
                    left: 50%;
                    margin-left: -3px;
                }

                &.bar-6 {
                    top: 50%;
                    margin-top: -3px;
                    right: 0;
                }

                &.bar-7 {
                    bottom: 0;
                    left: 0;
                }

                &.bar-8 {
                    bottom: 0;
                    left: 50%;
                    margin-left: -3px;
                }

                &.bar-9 {
                    bottom: 0;
                    right: 0;
                }
            }
        }

        .navbar-brand {
            padding: 5px 0;
            margin-left: 40px;

            @media (max-width: 1140px) and (min-width: 990px) {
                margin-left: 10px;
            }

            img {
                max-height: 65px;
                transition: 0.2s;
            }
        }

        .navbar-nav {
            align-items: center;

            .nav-item {
                .nav-link {
                    font-size: 15px;
                    color: $color-2;
                    padding: 5px 32px;
                    line-height: 1.25em;

                    @media (max-width: 1600px) {
                        padding: 5px 15px;
                    }

                    @media (max-width: 1300px) {
                        font-size: 14px;
                        padding: 5px 10px 5px 14px;
                    }

                    @media (max-width: 1199px) {
                        font-size: 13px;
                        padding: 5px 7px;
                    }

                    @media (max-width: 1020px) {
                        font-size: 12px;
                    }

                    &.upper {
                        font-size: 13px;
                        text-transform: uppercase;

                        @media (max-width: 1140px)  {
                            font-size: 12px;
                        }
                    }

                    .icon {
                        max-height: 20px;
                        margin-right: 10px;

                        &.icon-like {
                            max-height: 22px;
                        }
                    }
                }
            }
        }
    }
}

.menu-drawer {
    position: relative;

    .MuiPaper-root {
        background-color: $color-1;
        padding: 90px 90px 0 0;
        border: 0;
        max-width: 325px;
        overflow: initial;

        .img-logo {
            max-width: 102px;
            margin: 0 auto 15px;
            display: block;
        }

        @include media-breakpoint-down(sm) {
            max-width: 100%;
            padding: 70px 0 0;
            overflow: scroll;
        }

        > div {
            width: auto;
        }

        .filter {
            width: 100%;
            background-color: $color-1;
            position: absolute;
            top: 0;
            bottom: 0;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &:after {
            content: '';
            display: block;
            width: 0; 
            height: 0; 
            border-left: 90px solid transparent;
            border-right: 0 solid transparent;
            border-top: 100vh solid $color-1;
            left: -90px;
            position: absolute;
            top: 0;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &:before {
            content: '';
            display: block;
            width: 90px;
            height: 100vh;
            left: -64px;
            position: absolute;
            top: 0;
            background: rgba(255, 255, 255, 0.7);
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            z-index: -7;
            transform: skew(3.3deg, 0);
            position: absolute;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        .MuiList-root {
            margin-bottom: 10px;

            .MuiListItem-root {
                justify-content: flex-end;
                font-family: $poppinsregular;
                font-size: 14px;
                line-height: 21px;

                @include media-breakpoint-down(sm) {
                    display: block;
                    text-align: center;
                }

                &:first-child {
                    font-family: $poppinssemibold;
                    font-size: 18px;
                    line-height: 27px;
                }

                a {
                    color: $color-2;

                    &:hover {
                        text-decoration: none;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 15px;
                        background-color: $color-10;
                        padding: 18px;
                        display: block;
                        border-radius: 5px;
                    }
                }

                .btn-black {
                    background-color: $color-2;
                    color: $color-1;
                    max-width: 260px;
                    border-radius: 10px;
                    margin: 0 auto;
                    padding: 12px;

                    .icon {
                        max-height: 20px;
                        margin-right: 10px;
    
                        &.icon-like {
                            max-height: 22px;
                        }
                    }
                }
            }   
        }

        .btn-close {
            position: absolute;
            top: 20px;
            right: 20px;
    
            .icon {
                height: 25px;
            }
        }
    }
}

.breadcrumb {
    background-color: #f3f3f3;
    margin: 0;
    padding: 0;
    display: none;

    ol {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;

        li {
            margin: 0;
            padding: 0;
            position: relative;
        }

        li, a {
            color: #CECECE;
            font-size: 12px;
            line-height: 30px;
        }
    }

    .breadcrumb-item + .breadcrumb-item {
        padding-left: 34px;

        &::before {
            content: '';
            position: absolute;
            left: 15px;
            top: 11px;

            width: 0; 
            height: 0; 
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            
            border-left: 4px solid #cecece;
        }
    }

    .breadcrumb-item {
        &.active {
            color: #929292;
        }
    }

}