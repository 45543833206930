.broker-section {
    .broker-header {
        width: 100%;
        height: 250px;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .content {
        margin-top: -115px;
        margin-bottom: 70px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }

        .description {
            .info {
                margin-bottom: 20px;
    
                .text {
                    margin: 0;
                }
            }        
    
            .image {
                max-width: 200px;
                width: 100%;
                height: 200px;
                border-radius: 50%;
                box-shadow: 0px 5px 10px #00000026;
                -webkit-box-shadow: 0px 5px 10px #00000026;
                -moz-box-shadow: 0px 5px 10px #00000026;
                margin-bottom: 35px;
                background-position: center;
                background-size: cover;
    
                @include media-breakpoint-down(sm) {
                    margin: 0 auto 35px;
                }
            }

            .btn-whats {
                @include media-breakpoint-down(sm) {
                    display: block;
                    padding: 12px;
                }
            }

            @include media-breakpoint-down(sm) {
                padding: 0 25px;
            }
        }

        .block-form {
            box-shadow: -1px 3px 14px -3px #0000001A;
            -webkit-box-shadow: -1px 3px 14px -3px #0000001A;
            -moz-box-shadow: -1px 3px 14px -3px #0000001A;
            background-color: $color-0;
            padding: 35px 25px;
            border-radius: 10px;
            margin-top: 50px;

            .title {
                text-align: center;
                margin-bottom: 15px;
            }

            .form {
                .form-group {
                    margin-bottom: 15px;
                }

                .btn {
                    display: block;
                    width: 100%;
                }
            }

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    .result-content {

        .title-2 {
            text-align: center;
            margin-bottom: 30px;
        }

        .property-list {
            margin-bottom: 90px;
    
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
                padding: 20px 0 0;
            }
            
            .block-title {
                text-align: center;
    
                .title {
                    margin-bottom: 20px;
                }
            }
    
            .card {
                &.card-property {
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 25px;
                    }
                }
    
                &.card-broker-help {
                    height: 100%;
                    border: 0;
    
                    .info-banner {
                        height: 100%;
                        max-height: 400px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 40px;
                        position: relative;
                        overflow: hidden;
            
                        &:after {
                            content: '';
                            display: block;
                            width: 100%;
                            height: 100%;
                            background-color: #00000080;
                            position: absolute;
                        }
            
                        .block {
                            z-index: 9;
                            text-align: center;
                        }
            
                        .title {
                            color: $color-1;
                        }
            
                        .text {
                            color: $color-0;
                        }
                    }
    
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }
        }
    }
}

.broker-floating {

    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #0000001A;
    -webkit-box-shadow: 0px 3px 6px #0000001A;
    -moz-box-shadow: 0px 3px 6px #0000001A;
    padding: 15px;
    border-radius: 0 0 10px 10px;
    border-top: 2px solid #ffd300;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;

    @include media-breakpoint-down(sm) {
        bottom: 15px;
        left: 15px;
        right: 15px;
    }

    .broker-header {
        display: flex;
        margin-bottom: 15px;
        align-items: center;

        .broker-name {
            text-align: center;;
        }
    
        .broker-image {
            margin-right: 15px;
            background-size: cover;
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

    }
    
    .btn-whats {
        display: flex;
        align-items: center;
        padding: 12px ​25px;
    }

}