.banner-section {
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;

    @media (max-width: 767px) {
        min-height: 100vh;
        height: 100%;
        padding: 180px 0 100px;
    }

    .block-centered {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .title-1 {
            color: $color-0;
            text-align: center;
            margin-bottom: 40px;
            font-size: 40px;
            line-height: 45px;

            @media (max-width: 575px) {
                font-size: 32px;
                line-height: 40px;
                width: 300px;
            }

            span {
                display: block;
            }
        }

        .box-status {
            display: flex;

            .btn-3 {
                padding: 0;
                
                &.btn-alugar {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    height: 70px;
                }

                &.btn-comprar {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    height: 70px;
                }

                &.active {
                    color: #fff !important;
                }
            }
        }

        .box-status-comprar {
            display: none;

            &.active {
                display: flex;
            }

            .btn {
                border-radius: 0;
                height: 70px;
                padding: 0;
                
                @media (max-width: 767px) {
                    font-size: 14px;
                }

                @media (max-width: 575px) {
                    font-size: 12px;
                }

                &.active {
                    color: #fff !important;
                }

                &:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }

                &:last-child {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
        }

        .block-search {
            background-color: rgba(255, 255, 255, 0.5);
            //padding: 30px 40px;
            border-radius: 10px;
            max-width: 800px;
            
            width: 100%;
            position: relative;
            overflow: hidden;

            //max-width: 1100px;
            padding: 0;


            .block-filters {
                padding: 60px 60px 50px;
                max-width: inherit;

                @media (max-width: 767px) {
                    padding: 20px;
                }
            }

            .form {
                .form-group {
                    margin-bottom: 10px;
                }

                .form-control {
                    height: 70px;
                    padding: 25px 20px 5px;
                    appearance: none;

                    &.custom-select {
                        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADgSURBVHgBndAxDsIgFAbghx2LSYcmQLtwBL2Bbo7eQN0cHd08gt5Ar+DopJ6g3sBOhgO0Jh0qPho10UiLvqQBXn++AIRzPmoB9C5KTeCPwv1rHA5em9IVARhS3w+yPN/9ggghlniIKdFaemEYbsuyHBjMpxSyLDu6IHiSBSJz0Pp0LYp+1ZRSBpEQScS5NgEXxGQjxpIA6+1nhXF+bsJeCGZxLm0hWYc5Id8wxtjMFSE2DB9yb258AxibHq43OKS47iulUifoE3u0rEgt9MQ8QhIzL7Xu2hCniuO4Y76m3B26TlMZe0hC2QAAAABJRU5ErkJggg==);
                        background-size: auto;
                        background-position: center right 20px
                    }
                }

                .label-control {
                    margin: 0;
                    font-size: 12px;
                    color: #857979;
                    line-height: 12px;
                    top: 15px;
                    left: 20px;
                    position: absolute;
                }

                .block-toggle {
                    height: 70px;
                    padding: 25px 20px 5px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADgSURBVHgBndAxDsIgFAbghx2LSYcmQLtwBL2Bbo7eQN0cHd08gt5Ar+DopJ6g3sBOhgO0Jh0qPho10UiLvqQBXn++AIRzPmoB9C5KTeCPwv1rHA5em9IVARhS3w+yPN/9ggghlniIKdFaemEYbsuyHBjMpxSyLDu6IHiSBSJz0Pp0LYp+1ZRSBpEQScS5NgEXxGQjxpIA6+1nhXF+bsJeCGZxLm0hWYc5Id8wxtjMFSE2DB9yb258AxibHq43OKS47iulUifoE3u0rEgt9MQ8QhIzL7Xu2hCniuO4Y76m3B26TlMZe0hC2QAAAABJRU5ErkJggg==);
                    background-size: auto;
                    background-position: center right 20px;
                    background-repeat: no-repeat;

                    &:after {
                        display: none;
                    }
                }

                .block-control.active {
                    .block-toggle {
                        padding: 25px 20px 5px;
                        line-height: inherit;
                    }
                    
                    .text-1 {
                        display: none;
                    }
                }
            }

            .anageselect {
                //height: 130px;
                width: 100%;
                background: rgba(29, 40, 50, 0.3);
                padding: 30px 60px;

                @media (max-width: 767px) {
                    padding: 20px;
                }

                .logo-anageselect {
                    display: block;
                    width: 152px;
                    height: auto;
                    flex-shrink: 0;
                    margin: 0 20px 0 0;

                    @media (max-width: 991px) {
                        width: 120px;
                    }

                    @media (max-width: 575px) {
                        width: 76px;
                        margin: 0 18px 0 0;
                    }
                }

                .texto-anageselect {
                    font-family: "poppinsregular", sans-serif;
                    color: #1A1818;
                    font-size: 12px;
                    font-weight: 400;

                    @media (max-width: 575px) {
                        font-size: 11px;
                    }

                    strong {
                        font-weight: 700;
                    }
                }

                .btn-anageselect {
                    font-family: "poppinsregular", sans-serif;
                    font-size: 14px;
                    transition: 0.2s;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #1A1818;
                    color: #1A1818;
                    font-weight: 400;
                    border-radius: 10px;
                    position: relative;
                    background: transparent;
                    height: 70px;
                    padding: 0 20px 0 0;
                    white-space: nowrap;

                    @media (max-width: 991px) {
                        font-size: 12px;
                    }

                    @media (max-width: 767px) {
                        font-size: 16px;
                        margin-top: 30px;
                    }

                    &:after {
                        content: '';
                        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADgSURBVHgBndAxDsIgFAbghx2LSYcmQLtwBL2Bbo7eQN0cHd08gt5Ar+DopJ6g3sBOhgO0Jh0qPho10UiLvqQBXn++AIRzPmoB9C5KTeCPwv1rHA5em9IVARhS3w+yPN/9ggghlniIKdFaemEYbsuyHBjMpxSyLDu6IHiSBSJz0Pp0LYp+1ZRSBpEQScS5NgEXxGQjxpIA6+1nhXF+bsJeCGZxLm0hWYc5Id8wxtjMFSE2DB9yb258AxibHq43OKS47iulUifoE3u0rEgt9MQ8QhIzL7Xu2hCniuO4Y76m3B26TlMZe0hC2QAAAABJRU5ErkJggg==);
                        background-size: auto;
                        background-position: center;
                        position: absolute;
                        top: 28px;
                        right: 17px;
                        width: 18px;
                        height: 11px;
                        transform: rotate(-90deg);

                        @media (max-width: 991px) {
                            right: 12px;
                        }
                    }
                }
            }

            .blur {
                background: rgba(255, 255, 255, 0.2);
                backdrop-filter: blur(10px);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .additional-info {
                display: flex;
                height: 100%;
                align-items: flex-end;

                @include media-breakpoint-down(sm) {
                    display: block;
                }

                .form-group {
                    margin-right: 10px;
                    margin-bottom: 0;

                    @include media-breakpoint-down(sm) {
                        margin: 0 0 10px 0;
                    }

                    .form-control {
                        width: 250px;

                        &::placeholder {
                            color: $color-2 !important;
                        }
                        
                        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            color: $color-2 !important;
                        }
                        
                        &::-moz-placeholder { /* Firefox 19+ */
                            color: $color-2 !important;
                        }
                        
                        &:-ms-input-placeholder { /* IE 10+ */
                            color: $color-2 !important;
                        }
                        
                        &:-moz-placeholder { /* Firefox 18- */
                            color: $color-2 !important;
                        }
                    }

                    &:first-child {
                        .form-control {
                            &.small {
                                max-width: 165px;       

                                @include media-breakpoint-down(sm) {
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .btn-1 {
                margin: 0;
                height: 70px;
                width: 100%;
                padding: 0 20px 0 0;
                position: relative;
                font-size: 14px;

                @media (max-width: 991px) {
                    font-size: 12px;
                }

                @media (max-width: 767px) {
                    font-size: 16px;
                }

                &:after {
                    content: '';
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADgSURBVHgBndAxDsIgFAbghx2LSYcmQLtwBL2Bbo7eQN0cHd08gt5Ar+DopJ6g3sBOhgO0Jh0qPho10UiLvqQBXn++AIRzPmoB9C5KTeCPwv1rHA5em9IVARhS3w+yPN/9ggghlniIKdFaemEYbsuyHBjMpxSyLDu6IHiSBSJz0Pp0LYp+1ZRSBpEQScS5NgEXxGQjxpIA6+1nhXF+bsJeCGZxLm0hWYc5Id8wxtjMFSE2DB9yb258AxibHq43OKS47iulUifoE3u0rEgt9MQ8QhIzL7Xu2hCniuO4Y76m3B26TlMZe0hC2QAAAABJRU5ErkJggg==);
                    background-size: auto;
                    background-position: center;
                    position: absolute;
                    top: 28px;
                    right: 17px;
                    width: 18px;
                    height: 11px;
                    transform: rotate(-90deg);
                }
            }

            .btn-3 {
                width: 100%;
                margin-bottom: 20px;

                &.active {
                    color: $color-1;
                    background-color: $color-2;
                }
            }

            .btn-select {
                background-color: #1d2832;
                color: #ffd300;

                &:hover {
                    text-decoration: none;
                    border-color: #1d2832;
                }
            }

            /*.btn {
                @include media-breakpoint-down(sm) {
                    padding: 10px 5px;
                    white-space: normal;
                    height: calc(100% - 20px);
                    line-height: 1em;
                    font-size: 14px;
                }
            }*/
        }

        .btn-ver-lancamentos {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.featured-properties {
    padding: 90px 0 60px;
    text-align: center;

    @include media-breakpoint-down(sm) {
        padding: 65px 0 60px;
    }

    .text {
        margin-bottom: 30px;
    }
    
    .MuiBox-root {
        padding: 0;
    }

    .swiper-container {
        padding-bottom: 60px;

        @include media-breakpoint-down(sm) {
            padding-bottom: 50px;
        }

        .swiper-button-next,
                    .swiper-button-prev {
                        width: 30px;
                        height: 30px;
                        border-radius: 10px;
                        border: 2px solid #939393;
                        top: auto;
                        bottom: 0;
                        left: 50%;
                        z-index: 9;

                        &:after {
                            content: '';
                            display: block;
                            width: 10px;
                            height: 10px;
                            background-color: transparent;
                            border-top: 2px solid #939393;
                            border-right: 2px solid #939393;
                            top: 50%;
                            margin-top: -5px;
                            left: 50%;
                            margin-left: -7px;
                            position: absolute;
                        }

                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                    }

                    .swiper-button-next {
                        margin-left: 0px;
                        background-color: $color-1;
                        border-color: $color-1;

                        &:after {
                            transform: rotate(45deg);
                            border-color: $color-0;
                        }
                    }

                    .swiper-button-prev {
                        margin-left: -40px;

                        &:after {
                            transform: rotate(-135deg);
                            margin-left: -4px;
                        }
                    }
    }
}

.secondary-banners {
    padding: 90px 0 20px;
    background-color: $color-4;

    .swiper-container {
        padding: 0;
        width: 100%;
        max-width: 1040px;
        margin: 0 auto;

        .swiper-slide {
            height: 300px;

            .item {
                width: 100%;
                height: 300px;
                display: block;
                text-decoration: none;

                .image {
                    display: block;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 300px;
                }
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            width: 30px;
            height: 30px;
            border-radius: 10px;
            border: 2px solid #939393;

            &:after {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                background-color: transparent;
                border-top: 2px solid #939393;
                border-right: 2px solid #939393;
                top: 50%;
                margin-top: -5px;
                left: 50%;
                margin-left: -7px;
                position: absolute;
            }
        }

        .swiper-button-next {
            &:after {
                transform: rotate(45deg);
            }
        }

        .swiper-button-prev {
            &:after {
                transform: rotate(-135deg);
                margin-left: -4px;
            }
        }
    }
}

.info-modalities {
    background: linear-gradient(0deg, $color-0 75%, $color-4 25%);

    .swiper-container {
        &.slider-modalities {
            .swiper-button-next,
            .swiper-button-prev {
                width: 36px;
                height: 36px;
                top: 70%;
                margin-top: -20px;
                opacity: 1;

                &:after {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;
                    background-color: transparent;
                    border-top: 1px solid $color-0;
                    border-right: 1px solid $color-0;
                }
            }

            .swiper-button-next {
                background-color: $color-1;
                right: 80px;
                border-radius: 0 10px 10px 0;

                &:after {
                    transform: rotate(45deg);
                    margin-top: 12px;
                    margin-left: 8px;
                }
            }

            .swiper-button-prev {
                border: 1px solid $color-7;
                border-radius: 10px 0 0 10px;
                left: auto;
                right: 115px;

                &:after {
                    border-color: $color-7;
                    transform: rotate(-135deg);
                    margin-top: 12px;
                    margin-left: 12px;
                }
            }
        }

        &.slider-info {
            padding-bottom: 100px;

            .swiper-button-next,
            .swiper-button-prev {
                width: 40px;
                height: 40px;
                top: 70%;
                margin-top: -20px;
                opacity: 1;
                z-index: 9;

                &:after {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;
                    background-color: transparent;
                    border-top: 1px solid $color-0;
                    border-right: 1px solid $color-0;
                }
            }

            .swiper-button-next {
                background-color: $color-7;
                right: auto;
                left: 40px;

                &:after {
                    transform: rotate(45deg);
                    margin-top: 14px;
                    margin-left: 10px;
                }
            }

            .swiper-button-prev {
                background-color: $color-4;
                left: 0;

                &:after {
                    border-color: $color-2;
                    transform: rotate(-135deg);
                    margin-top: 14px;
                    margin-left: 16px;
                }
            }
        }
    }

    .block-info {
        padding: 80px 90px;
        margin: 70px 115px 20px;
        border-radius: 10px;
        -webkit-box-shadow: 0px 5px 12px -1px $color-3; 
        box-shadow: 0px 5px 12px -1px $color-3;
        background-color: $color-0;

        @include media-breakpoint-down(md) {
            margin: 70px 50px 20px;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 0 20px;
            padding: 40px 35px;
        }

        .text {
            margin-bottom: 40px;
        }

        .item {
            max-width: 200px;
            padding-bottom: 30px;

            @include media-breakpoint-down(sm) {
                padding: 0;
            }

            .icon {
                max-height: 25px;
                margin-bottom: 15px;
            }

            p {
                strong {
                    font-family: $poppinsbold;
                }
            }
        }
    }
}

.searches-block {
    text-align: center;
    padding: 60px 0;

    @include media-breakpoint-down(sm) {
        padding: 40px 0;
    }

    .MuiBox-root {
        padding: 0;
    }

    .MuiPaper-root {
        max-width: 890px;
        border-color: #1A1818;
        margin: 0 auto 60px;

        @media (max-width: 991px) {
            max-width: 690px;
        }

        @media (max-width: 767px) {
            max-width: 475px;
            margin: 0 auto 40px;
        }
    }

    .MuiPaper-root.MuiAppBar-colorDefault {

        .MuiTab-root {
            margin: 0;
            height: 70px;
            border-radius: 0;
            font-size: 20px;
            font-weight: 400;

            @media (max-width: 767px) {
               font-size: 18px;
            }
        }

        .Mui-selected {
            background-color: #1A1818;
            color: #fff;
        }
    }

    .words-block {
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-down(sm) {
            font-size: 12px;
            justify-content: space-between;
        }

        &.popular-words {
            .btn {
                width: auto;

                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                    width: 100%;
                    margin: 10px 0;
                    padding: 15px 10px;
                }
            }
        }

        .btn {
            width: calc(100% / 4 - 20px);
            margin-right: 20px;

            @media (max-width: 991px) {
                width: calc(100% / 3 - 20px);
            }

            @include media-breakpoint-down(sm) {
                width: calc(100% / 2 - 10px);
                margin-right:0
            }
        }

        .btn-3 {
            font-size: 14px;
            color: #939393;
            margin: 10px;
            border-color: $color-3;
            padding: 15px 30px;
            
            &:hover {
                background-color: $color-0;
            }
        }

        &.interest-block {
            text-align: left;

            .btn-arrow {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-direction: column;
                background-color: #F4F4F4;
                white-space: inherit;
                color: $color-2;
                text-transform: none;
                padding: 30px;
                margin-bottom: 20px;
                height: 206px;
                font-size: 20px;
                font-weight: 500;
                line-height: 22px;
                text-align: left;

                @media (max-width: 1199px) {
                    font-size: 16px;
                    line-height: 20px;
                }

                @media (max-width: 991px) {
                    font-size: 14px;
                    line-height: 18px;
                }

                @media (min-width: 992px) {
                    transform: all 0.3s ease;
                    
                    &:hover {
                        box-shadow: 0 5px 12px -1px #cecece;
                    }
                }

                img {
                    display: block;
                    margin: 0 0 20px;
                    max-width: 60px;
                    max-height: 60px;

                    @include media-breakpoint-down(sm) {
                        max-width: 30px;
                        max-height: 30px;
                    }
                }

                @include media-breakpoint-down(sm) {
                    padding: 20px;
                    height: auto;
                }
            }
        }
    }
}

.info-ad {
    padding: 100px 0 90px;
    background-color: $color-4;

    @include media-breakpoint-down(sm) {
        padding: 40px 0 70px;
    }

    .title-2 {
        margin-bottom: 5px;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    .title-3 {
        color: $color-1;
        margin-bottom: 50px;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    .block {
        .item {
            display: flex;
            padding-bottom: 30px;
            padding-left: 30px;
            position: relative;

            .icon {
                max-height: 25px;
            }

            .item-info {
                padding-left: 30px;
                
                @include media-breakpoint-down(sm) {
                    font-size: 15px;
                }

                strong {
                    font-family: $poppinsbold;
                }
            }
        }
    }

    .btn-1 {
        @include media-breakpoint-down(sm) {
            margin: 0 auto 70px;
            max-width: 190px;
            display: block;
        }
    }

    .block-images {
        .img-ad {
            margin-bottom: 40px;
        }

        .stamp {
            display: flex;
            align-items: center;
            max-width: 420px;

            @include media-breakpoint-down(sm) {
                align-items: flex-start;
            }

            .img-stamp {
                margin-right: 20px;

                @include media-breakpoint-down(sm) {
                    max-width: 100px;
                }
            }
        }
    }
}

.blog-block {
    .content {
        border-top: 2px solid #e6e6e6;
        padding: 70px 0;

        @include media-breakpoint-down(sm) {
            padding: 40px 0;
        }
        
        .title-block {
            .text {
                margin-bottom: 30px;
            }

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        .blog-item {
            height: 275px;
            display: block;
            border-radius: 10px;
            overflow: hidden;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 30px;
            
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }

            > div {
                padding: 35px;
                background-color: rgba(0,0,0,0.5);
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: flex-end;
                height: 100%;
            }

            .title {
                color: $color-0;
                font-size: 20px;
                line-height: 1.5em;
            }

            span {
                color: $color-1;
                text-transform: uppercase;
                font-size: 14px;
            }
        }

        .social-media {
            .list {
                list-style: none;
                display: flex;

                .list-item {
                    padding-right: 50px;

                    @include media-breakpoint-down(sm) {
                        padding-right: 20px;
                    }

                    .list-link {
                        display: flex;
                        align-items: center;

                        .icon {
                            max-height: 22px;
                            margin-right: 10px;
                        }

                        &.instagram {
                            color: #ec3bb1;
                        }

                        &.facebook {
                            color: #0090ff;
                        }

                        &.youtube {
                            color: #f85b59;
                        }
                    }
                }
            }
        }
    }
}

.newsletter-section {
    background-color: $color-1;
    padding: 30px 0;
    position: relative;

    .info {
        margin: 0 0 20px;

        .title,
        .text {
            margin: 0;

            span {
                font-family: $poppinsregular;
                display: block;

                @include media-breakpoint-down(sm) {
                    display: inline;
                }
            }

            @include media-breakpoint-down(sm) {
                font-size: 15px;
                margin-bottom: 15px;
            }
        }
    }

    .form {        
        .form-group {
            position: relative;
            margin: 0;

            @include media-breakpoint-down(sm) {
                margin-bottom: 10px;
            }

            .label-control {
                font-size: 12px;
                position: absolute;
                top: 7px;
                left: 22px;
            }

            .form-control {
                font-size: 16px;
                border: 2px solid $color-0;
                background-color: $color-0;
                height: 55px;
                padding: 20px 20px 5px;

                &::placeholder {
                    color: #cf9800 !important;
                }
                
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: #cf9800 !important;
                }
                
                &::-moz-placeholder { /* Firefox 19+ */
                    color: #cf9800 !important;
                }
                
                &:-ms-input-placeholder { /* IE 10+ */
                    color: #cf9800 !important;
                }
                
                &:-moz-placeholder { /* Firefox 18- */
                    color: #cf9800 !important;
                }
            }
        }

        .custom-select {
            
        }

        .custom-control {
            &.small-checkbox {
                margin-top: 10px;
                margin-bottom: 0;

                .custom-control-label {
                    color: $color-2;
                    padding: 3px 0 0 0;

                    &:before {
                        border-color: $color-2;
                        width: 15px;
                        height: 15px;
                    }

                    &:after {
                        width: 5px;
                        height: 5px;
                        left: -19px;
                    }
                }

                .custom-control-input:checked ~ .custom-control-label::before {
                    background-color: transparent;
                }
            }
        }

        .btn {
            width: 100%;

            //@include media-breakpoint-down(sm) {
            //    width: 100%;
            //}
        }
    }
}
