.result-content {

    .toggle-animation {
        transition: flex 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .filters-section {
        background-color: #FBFBFB;
        padding: 0 0 30px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        
        @include media-breakpoint-down(sm) {
            padding: 30px;
            margin: 0 -15px;
        }
    }

    .property-list {
        padding: 20px 0 60px;

        .filters-info {
            margin-bottom: 10px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 25px;
            }

            .block-left {
                text-align: left;

                .title {
                    margin: 0;
                }

                .text {
                    color: $color-3;
                }
            }

            .block-right {
                text-align: right;

                .MuiInputBase-root {
                    .MuiNativeSelect-root {
                        font-family: $poppinsregular;
                        font-size: 15px;
                        line-height: 25px;
                        color: $color-10;
                        padding: 0 20px 0 0;

                        &:focus {
                            background-color: $color-0;
                        }
                    }

                    .MuiNativeSelect-icon {
                        color: $color-2;
                        top: 0;
                    }

                    &:before, &:after {
                        display: none;
                    }
                }
            }
        }

        .sub-filters {
            .line {
                border-top: 2px solid #e6e6e6;
                padding: 28px 0 14px;

                .filter-items {
                    display: flex;
                    flex-wrap: wrap;

                    .filter-item {
                        cursor: pointer;
                        font-size: 15px;
                        color: $color-7;
                        border-radius: 6px;
                        border: 1px solid $color-7;
                        margin-right: 15px;
                        margin-bottom: 15px;
                        padding: 8px 16px;

                        &.active {
                            color: $color-1;
                            border-color: $color-1;
                        }
                    }
                }
            }
        }

        .card-property {
            margin-bottom: 20px;
        }

        .banner {
            padding: 65px 0 55px;
            border-radius: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            .description {
                text-align: center;

                .title {
                    color: #FFD300;
                }

                .text {
                    color: $color-0;
                }
            }
        }
    }

    .right-side {
        position: fixed;
        width: inherit;
        height: 100vh;
        margin: 0 -15px;

        max-width: calc(33% + 5px);
        max-height: calc(100vh - 77px);

        @include media-breakpoint-down(sm) {
            position: relative;
            max-width: calc(100% + 30px);
            width: calc(100% + 30px);
            height: 50vh;
        }

        .swiper-container {
            height: 100%;

            .swiper-slide {
                width: 100%;
                height: 100%;

                a {
                    background-size: auto 100%;
                    background-repeat: no-repeat;
                    display: flex;
                    width: 100%;
                    height: 100%;
                }

            }

            .swiper-pagination {
                display: block;
                bottom: 100px;
                max-width: 33%;

                .swiper-pagination-bullet {
                    border: 1.5px solid #ffffff;
                    opacity: 1;
                    background-color: transparent;
                    margin: 0 7px;

                    &.swiper-pagination-bullet-active {
                        background-color: #ffffff;
                    }
                }
            }
        }
    }

    .col-property-list {
        
        &.mapVisible {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

    }
}

.filters-section {

    .block-filters {
        width: 100%;
        margin: 0 auto !important;
        padding: 20px 20px 0;
        position: relative;
        max-width: 900px;

        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        .switch-item {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 9;

            @include media-breakpoint-down(sm) {
                position: relative;
                right: -33%;
            }

            &.active {
                right: -33%;
            }

            .btn-switch {
                padding: 0 10px 0 59px;
                height: 30px;
                position: relative;
                border-radius: 25px;
                background-color: transparent;

                &:focus {
                    box-shadow: none;
                }

                &:before {
                    content: '';
                    display: block;
                    background-color: $color-3;
                    border-radius: 25px;
                    width: 49px;
                    height: 30px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: 0.2s;
                }

                &:after {
                    content: '';
                    display: block;
                    background-color: $color-0;
                    border-radius: 25px;
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    top: 4px;
                    left: 4px;
                    transition: 0.2s;
                }

                &.active {
                    &:before {
                        background-color: $color-1;
                    }

                    &:after {
                        left: 23px;
                    }    
                }
            }
        }

        .block-actions {
            
        }
    }

    .form {
        .block-control {
            margin-bottom: 15px;
            position: relative;

            &.active {
                
                .block-toggle {
                    padding-top: 7px;
                    line-height: 1em;

                    .text-1 {
                        font-size: 12px;
                        line-height: 1em;
                    }
                    .text-2 {
                        font-size: 15px;
                    }
                }    
            }

            .block-toggle {
                border: 1px solid $color-3;
                height: 55px;
                width: 100%;
                border-radius: 10px;
                position: relative;
                padding: 14px 20px;
                background-color: $color-0;
                // z-index: 2;

                &:after {
                    content: '';
                    display: block;
                    width: 0; 
                    height: 0; 
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid $color-2;
                    transition: 0.2s;
                    position: absolute;
                    top: 24px;
                    right: 20px;
                }
            }

            .block-content {
                // visibility: hidden;
                // height: 0;
                // opacity: 0;
                width: 100%;
                padding: 17px 20px 10px;
                background-color: $color-0;
                border: 1px solid $color-3;
                border-top: 0;
                margin-top: -7px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                position: absolute;
                top: 50px;
                z-index: 1;

                .form-group {
                    margin-bottom: 5px;

                    .label {
                        font-size: 12px;
                        color: $color-7;
                    }

                    .form-control {
                        border-radius: 10px;
                    }
                }
            }
        }

        .block-items {
            margin-top: -10px;
            margin-bottom: 20px;

            .text {
                margin-bottom: 0;
                text-align: center;
            }

            .actions {
                text-align: center;

                .btn {
                    margin: 0 5px;
                }
            }
        }

        .checkbox-group {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 25px;

            .custom-control {
                width: calc(100% / 4);

                @include media-breakpoint-down(sm) {
                    width: calc(100% / 2);
                    margin-bottom: 5px;
                }
            }
        }

        .input-group {
            .normal-group {
                position: relative;
                margin-right: 20px;

                @include media-breakpoint-down(sm) {
                    margin-right: 0;
                }

                .form-control {
                    font-size: 12px;
                    color: $color-2;
                    padding: 10px 20px;
                    height: 34px;
                    border-radius: 8px;

                    &::placeholder {
                        font-size: 12px;
                        color: $color-2 !important;
                    }
                }

                &:first-child {
                    max-width: 190px;
                    width: 100%;

                    @include media-breakpoint-down(sm) {
                        max-width: 100%;
                    }
                }

                &:nth-child(2) {
                    max-width: 250px;
                    width: 100%;

                    @include media-breakpoint-down(sm) {
                        max-width: 100%;
                    }
                }

                .btn {
                    font-size: 12px;
                    color: $color-0;
                    padding: 7px 10px;
                    background-color: $color-2;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }

        .interest-block {
            margin-bottom: 15px;
            
            .item {
                font-size: 14px;
                background-color: #F3F3F3;
                border-radius: 10px;
                margin-bottom: 10px;
                display: block;
                padding: 10px;
                text-align: center;
            }
        }

        .block-submit {
            text-align: right;
        }
    }
}