li:hover ul { 
    display: block; 
}

ul li a:hover{
    text-decoration:underline;
}

.over .dropdown-menu {
    display: block;
}

.title-showcase {
    color: #ffffff;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.4em;
    letter-spacing: 0.1px;
    text-shadow: 2px 2px 2px #ef6b00;
}

@media (min-width: 700px) { 
    li:hover ul, li:focus ul, li:active ul { 
        display: block; 
    }
}

@media (min-width: 576px) {
    li:hover ul, li:focus ul, li:active ul { 
        display: block; 
    } 
}
  @media (min-width: 768px) {
    li:hover ul, li:focus ul, li:active ul { 
        display: block; 
    }
}
  @media (min-width: 992px) {
    li:hover ul, li:focus ul, li:active ul { 
        display: block; 
    }
}
  @media (min-width: 1200px) {
    li:hover ul, li:focus ul, li:active ul { 
        display: block; 
    }
}

