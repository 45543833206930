.contact-section {
    .page-header {
        &.large {
            @include media-breakpoint-down(sm) {
                padding: 130px 0 80px;
            }
        }
    }

    .content {
        .form-content {
            padding: 100px 0 60px;

            @include media-breakpoint-down(sm) {
                padding: 55px 0 60px;
            }

            .form {
                @include media-breakpoint-down(sm) {
                    margin-bottom: 40px;
                }
            }

            .text {
                margin-bottom: 35px;
            }

            .btn {
                width: 100%;
                margin-bottom: 15px;
            }

            .inline-group {
                display: flex;
                align-items: center;
                margin-bottom: 35px;

                .text {
                    margin: 0 20px 0 0;
                }

                .form-control {
                    max-width: 165px;
                }
            }
        }

        .shops {
            background-color: $color-4;
            padding: 70px 0 50px;

            @include media-breakpoint-down(sm) {
                padding: 30px 0 20px;
            }

            .block-title {
                text-align: center;
                margin-bottom: 40px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 30px;
                }
            }

            .shops-list {
                .card-shop {
                    background-color: $color-0;
                    box-shadow: -1px 3px 14px -3px #0000001A;
                    -webkit-box-shadow: -1px 3px 14px -3px #0000001A;
                    -moz-box-shadow: -1px 3px 14px -3px #0000001A;
                    margin-bottom: 40px;
                    border-radius: 10px;

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 30px;
                    }

                    .image {
                        width: 100%;
                        height: 225px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        border-radius: 10px;
                    }

                    .description {
                        padding: 30px 40px;

                        @include media-breakpoint-down(sm) {
                            padding: 30px;
                        }
        
                        .title {
                            color: $color-5;
                        }
            
                        .text {
                            margin-bottom: 10px;
                        }
            
                        .tel {
                            color: $color-2;
            
                            small {
                                font-size: 12px;
                            }
        
                            .icon {
                                height: 20px;
                                margin-left: 5px;
                            }
                        }
            
                        a {
                            display: block;
                        }
                    }
                }
            }
        }

        .about-city {
            padding: 80px 0 40px;

            @include media-breakpoint-down(sm) {
                padding: 50px 0 40px;
            }

            .title {
                margin-bottom: 30px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 10px;
                }
            }

            .text {
                margin-bottom: 40px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 25px;
                }
            }

            .btn-1 {
                @include media-breakpoint-down(sm) {
                    max-width: 326px;
                    width: 100%;
                    margin-bottom: 50px;
                }
            }
        }
    }
}

.faq-section {
    .content {
        background-color: #FBFBFB;
        padding: 75px 0;

        @include media-breakpoint-down(sm) {
            padding: 65px 0;
        }

        .accordion-block {
            .accordion-group {
                margin-bottom: 100px;
                
                @include media-breakpoint-down(sm) {
                    margin-bottom: 40px;
                }

                .title-2 {
                    color: $color-10;
                    margin-bottom: 35px;

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 10px;
                    }
                }

                .MuiPaper-root {
                    margin: 0;
                    padding: 15px 0;
                    border-top: none;
                    border-right: none;
                    border-left: none;
                    box-shadow: none;
                    max-width: 100%;
                    background-color: transparent;

                    .MuiButtonBase-root {
                        padding: 0;
                        min-height: auto;

                        &.Mui-expanded {
                            &:after {
                                transform: rotate(180deg);
                            }    
                        }

                        &:after {
                            content: '';
                            display: block;
                            width: 0; 
                            height: 0; 
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 5px solid $color-2;
                            transition: 0.2s;
                        }

                        .MuiAccordionSummary-content {
                            margin: 0;
                        }
                    }

                    .MuiCollapse-container {
                        .MuiTypography-root {
                            &.text-1 {
                                margin-top: 15px;
                            }
                        }
                    }

                    .MuiAccordionDetails-root {
                        padding: 0;
                    }

                    &:before {
                        display: none;
                    }
                }
            }
        }

        .info {
            .btn {
                @include media-breakpoint-down(sm) {
                    max-width: 260px;
                    width: 100%;
                }
            }
            
            @include media-breakpoint-down(sm) {
                margin-bottom: 50px;
            }
        }

        .shops-block {
            box-shadow: -1px 3px 14px -3px #0000001A;
            -webkit-box-shadow: -1px 3px 14px -3px #0000001A;
            -moz-box-shadow: -1px 3px 14px -3px #0000001A;
            padding: 50px 50px 20px;
            border-radius: 10px;
            overflow: hidden;

            .title-2 {
                margin-bottom: 30px;
            }

            .description {
                .title {
                    color: $color-5;
                }
    
                .tel {
                    color: $color-2;
    
                    small {
                        font-size: 12px;
                    }

                    .icon {
                        height: 20px;
                        margin-left: 5px;
                    }
                }
    
                a {
                    display: block;
                }
            }
        }
    }
}