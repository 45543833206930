.about-section {
    padding: 100px 0 50px;

    @include media-breakpoint-down(sm) {
        padding: 70px 0 20px;
    }

    .info {
        margin-bottom: 60px;

        .title {
            @include media-breakpoint-down(sm) {
                margin-bottom: 40px;
            }
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 50px;
        }
    }

    .differentials {
        margin: 60px 0 90px;
        height: 420px;

        @include media-breakpoint-down(sm) {
            margin: 55px 0 50px;
        }
        
        .slider-block {
            position: relative;
            
            .title-2 {
                text-align: center;
                margin-bottom: 25px;
            }
            
            .position {
                // position: absolute;
                // left: 50%;
                // margin-left: -570px;
                // width: 100%;

                // @include media-breakpoint-down(lg) {
                //     position: initial;
                //     margin: 0 15px;
                //     width: auto;
                // }

                // @include media-breakpoint-down(sm) {
                //     position: initial;
                //     margin: 0;
                // }

                .swiper-container {
                    padding-bottom: 60px;
                    
                    .swiper-slide {
                        height: 300px;
                        padding: 40px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        border-radius: 10px;

                        .icon {
                            display: block;
                            margin-bottom: 20px;
                        }

                        .title {
                            color: $color-0;
                        }
                    }

                    .swiper-button-next,
                    .swiper-button-prev {
                        width: 30px;
                        height: 30px;
                        border-radius: 10px;
                        border: 2px solid #939393;
                        top: auto;
                        bottom: 0;
                        left: 50%;
                        z-index: 9;

                        &:after {
                            content: '';
                            display: block;
                            width: 10px;
                            height: 10px;
                            background-color: transparent;
                            border-top: 2px solid #939393;
                            border-right: 2px solid #939393;
                            top: 50%;
                            margin-top: -5px;
                            left: 50%;
                            margin-left: -7px;
                            position: absolute;
                        }

                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                    }

                    .swiper-button-next {
                        margin-left: 0px;
                        background-color: $color-1;
                        border-color: $color-1;

                        &:after {
                            transform: rotate(45deg);
                            border-color: $color-0;
                        }
                    }

                    .swiper-button-prev {
                        margin-left: -40px;

                        &:after {
                            transform: rotate(-135deg);
                            margin-left: -4px;
                        }
                    }
                }
            }
        }
    }

    .video-block {
        margin-bottom: -210px;

        @include media-breakpoint-down(sm) {
            margin-bottom: -110px;
        }

        .player-item {
            max-width: 740px;
            max-height: 416px;
            margin: 0 auto;
            border-radius: 10px;

            @include media-breakpoint-down(sm) {
                max-height: 200px;
            }
        }
    }

    .our-services {
        padding: 310px 0 110px;
        background-color: $color-1;
        text-align: center;

        @include media-breakpoint-down(sm) {
            padding: 160px 0 60px;
        }

        .block-title {
            margin-bottom: 40px;
        }

        .info {
            padding: 0 20px;
            height: 310px;
            position: relative;

            .icon {
                margin-bottom: 20px;
            }

            .btn {
                position: absolute;
                bottom: 0;
                max-width: 180px;
                width: 100%;
                left: 50%;
                margin-left: -90px;
            }
        }
    }

    .shops {
        padding: 90px 0;
        background-color: $color-4;

        @include media-breakpoint-down(sm) {
            padding: 75px 0 25px;
        }

        .block-title {
            text-align: center;
            margin-bottom: 45px;
        }

        .item {
            .info-centered {
                display: flex;
                height: 100%;
                align-items: center;
            }

            .description {
                padding-right: 50px;

                .title {
                    color: $color-5;
                }
    
                .text {
                    margin-bottom: 10px;
                }
    
                .tel {
                    color: $color-2;
    
                    small {
                        font-size: 12px;
                    }

                    .icon {
                        height: 20px;
                        margin-left: 5px;
                    }
                }
    
                a {
                    display: block;
                }
            }
        }
    }

    .partners {
        padding-top: 90px;

        @include media-breakpoint-down(sm) {
            padding-top: 75px;
        }
        
        .block-title {
            text-align: center;
            margin-bottom: 40px;

            @include media-breakpoint-down(sm) {
                padding-bottom: 30px;
            }
        }

        .item {
            text-align: center;

            @include media-breakpoint-down(sm) {
                padding-bottom: 70px;
            }

            .title {
                margin-bottom: 25px;
            }

            .image {
                max-width: 130px;
                margin: 0 auto 30px;
                display: block;
            }

            .title-4 {
                color: $color-10;
            }
        }
    }
}