.btn {
    font-family: $poppinsregular;
    font-size: 16px;
    padding: 15px 50px;
    position: relative;
    border-radius: 10px;
    transition: 0.2s;

    &.btn-1 {
        color: $color-2;
        text-transform: none;
        background-color: $color-1;
        padding: 12px 30px;
        border-radius: 10px;

        &:hover {
            background-color: $color-10;
        }

        @include media-breakpoint-down(sm) {
            padding: 12px;
        }
    }

    &.btn-2 {
        color: $color-1;
        background-color: $color-2;
        padding: 12px;
        border-radius: 10px;

        &:hover {
            color: $color-10;
        }
    }

    &.btn-3 {
        color: $color-2;
        background-color: transparent;
        border: 1px solid $color-2;
        padding: 12px 50px;
        border-radius: 10px;

        &:hover {
            background-color: $color-1;
            border-color: $color-1;
        }
    }

    &.btn-4 {
        color: $color-0;
        background-color: #30a0eb;

        &:hover {
            background-color: #007ccf;
        }
    }

    &.btn-5 {
        color: $color-0;
        text-transform: none;
        background-color: $color-9;
        padding: 12px 30px;
        border-radius: 10px;

        &:hover {
            background-color: $color-9;
        }
    }

    &.btn-6 {
        color: $color-0;
        text-transform: none;
        background-color: $color-5;
        padding: 12px 30px;
        border-radius: 10px;

        &:hover {
            background-color: $color-5;
        }
    }

    &.btn-7 {
        color: $color-0;
        background-color: transparent;
        border: 1px solid $color-0;
        padding: 12px 50px;
        border-radius: 10px;
    }

    &.btn-8 {
        font-size: 26px;
        color: $color-0;
        background-color: $color-5;
        border: 1px solid $color-0;

        @include media-breakpoint-down(sm) {
            font-size: 18px;
        }
    }

    &.btn-9  {
        color: $color-3;
        background-color: transparent;
        border: 1px solid $color-3;
        padding: 12px 30px;
        border-radius: 10px;
    }

    &.btn-link {
        color: $color-3;
        padding-right: 0;
        padding-left: 0;

        &:hover {
            text-decoration: none;

            &:after {
                width: 100%;
            }
        }

        &:after {
            content: '';
            display: block;
            height: 2px;
            width: 75px;
            background-color: $color-5;
            position: absolute;
            bottom: 0;
            transition: 0.2s;
        }
    }

    &.btn-back {
        color: $color-7;
        background-color: transparent;
        padding: 12px 50px;
        border-radius: 10px;

        .icon {
            height: 14px;
            margin-right: 10px;
        }
    }

    &.btn-whats {
        color: $color-6;
        background-color: transparent;
        border: 1px solid $color-6;
        padding: 12px 50px;
        border-radius: 10px;

        .icon {
            height: 14px;
            margin-right: 10px;
        }
    }

    &.btn-whats-small {
        background-color: #4EC43C;
        padding: 12px;
        border-radius: 10px;

        .icon {
            height: 22px;
            display: block;
        }
    }

    &.new-whatsapp {
        background: #4EC43C;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        color: #fff;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;

        @media (max-width: 991px) and (min-width:768px) {
            font-size: 12px;
        }

        svg {
            width: 18px;
            height: 18px;
            fill: #fff;
            margin: 0 10px 2px 0;
            transition: all 0.3s ease;
        }

        @media (min-width: 992px) {
            transition: all 0.3s ease;
            
            &:hover {
                background: #fb0;
                border: 1px solid #fb0;
                color: #1A1818;
                text-decoration: none;

                svg {
                    fill: #1A1818;
                }
            }
        }
    }

    &.new-visit,
    &.new-visit2 {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        text-decoration: none;

        @media (max-width: 991px) and (min-width:768px) {
            font-size: 12px;
        }

        svg {
            width: 18px;
            height: 18px;
            margin: 0 10px 2px 0;
        }

        @media (min-width: 992px) {
            transition: all 0.3s ease;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &.new-visit {
        background: #fff;
        color: #1A1818;
        border: 1px solid #1A1818;

        svg {
            fill: #1A1818;
        }

        @media (min-width: 992px) {
            &:hover {
                background: #fb0;
                border: 1px solid #fb0;
            }
        }
    }

    &.new-visit2 {
        background: transparent;
        color: #ffd300;
        border: 1px solid #ffd300;

        svg {
            fill: #ffd300;
        }

        @media (min-width: 992px) {
            &:hover {
                background: #fb0;
                border: 1px solid #fb0;
                color: #1A1818;

                svg {
                    fill: #1A1818;
                }
            }
        }
    }

    &.btn-lg {
        padding: 15px;

        @include media-breakpoint-down(sm) {
            padding: 10px 15px;
        }
    }

    &.btn-small {
        width: 40px;
        height: 40px;
        padding: 5px;
        border: 1px solid $color-3;
        background-color: $color-0;

        &.active {
            color: $color-1;
            background-color: $color-2;
            border-color: $color-2;
        }
    }

    &.btn-visit {
        padding: 5px;
        max-width: 80px;
        width: 100%;
        height: 80px;
    }

    &.btn-favorite {
        font-size: 13px;
        position: relative;
        padding: 10px 30px;
        background-color: transparent;

        .icon {
            max-height: 22px;
            position: absolute;
            top: 8px;
            left: 0;
        }

        &:focus {
            box-shadow: none;
        }
    }

    &.toggleSearch {
        background-color: transparent;
        padding-left: 0;

        &:after {
            content: '';
            display: block;
            width: 0; 
            height: 0; 
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid $color-2;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -2px;
        }

        &:focus {
            box-shadow: none;
        }
    }

    &.btn-whats-floating {
        background-color: #4EC43C;
        padding: 17px;
        border-radius: 50%;
        position: fixed;
        bottom: 105px;
        right: 35px;

        @include media-breakpoint-down(md) {
            bottom: 20px;
            right: 20px;
        }

        .icon {
            height: 22px;
            display: block;
        }
    }
}

#blip-chat-container {
    z-index: 9 !important;
    @include media-breakpoint-down(md) {
        display: none !important;
    }
    #blip-chat-notifications {
        bottom: 35px !important;
}
}


body.realestate {
    #blip-chat-container,
    .btn.btn-whats-floating {
        display: none !important;
    }
}