.card {
    &.card-property {
        border: 1px solid $color-3;
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        .tags {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 9;
            display: flex;

            .tag {
                font-family: $poppinsregular;
                font-size: 12px;
                line-height: 8px;
                border-radius: 5px;
                padding: 10px 20px;
                margin-left: 10px;
            }

            .black {
                color: $color-1;
                background-color: $color-2;
            }
    
            .yellow {
                color: $color-2;
                background-color: $color-1;
            }
        }


        .property-images {
            .swiper-container {
                padding: 0;
            }

            .swiper-slide {
                .image {
                    height: 245px;
                    width: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                .count {
                    background-color: #FBFBFB;
                    font-family: $poppinsregular;
                    font-size: 10px;
                    line-height: 2em;
                    border-radius: 5px;
                    padding: 0px 5px;

                    position: absolute;
                    bottom: 15px;
                    right: 15px;
                    z-index: 9;
                }
            }

            .swiper-pagination {
                display: block;
                
                .swiper-pagination-bullet {
                    width: 8px;
                    height: 8px;
                    background-color: $color-0;
                    opacity: 0.7;
                    margin: 2px 10px;
                    transition: 0.2s;
        
                    &.swiper-pagination-bullet-active {
                        opacity: 1;    
                    }
                }
            }

            .swiper-button-next,
            .swiper-button-prev {
                width: 50px;
                background-color: rgba(0, 0, 0, 0.4);
                height: 100%;
                margin: 0;
                top: 0;
                opacity: 0;
                transition: 0.2s;
                border-radius: 0;
                border: 0;

                &:after {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;
                    background-color: transparent;
                    border-top: 2px solid $color-0;
                    border-right: 2px solid $color-0;
                    top: 50%;
                    margin-top: -8px;
                    position: absolute;
                }
            }

            .swiper-button-prev {
                left: 0;

                &:after {
                    transform: rotate(-135deg);
                    left: 50%;
                    margin-left: -5px;
                }
            }

            .swiper-button-next {
                right: 0;
                left: auto;

                &:after {
                    transform: rotate(45deg);
                    left: 50%;
                    margin-left: -10px;
                }
            }
            
            &:hover {
                .swiper-button-next,
                .swiper-button-prev {
                    opacity: 1;
                }
            }
        }

        .description {
            color: $color-2;
            padding: 20px;
            text-align: left;

            .info {
                display: flex;

                &.top-info {
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    a {
                        color: #1a1818;
                        text-decoration: none;
                        &:hover {
                            text-decoration: none;
                        }
                    }

                    .block-title {


                        .subtitle {
                            font-family: $poppinsbold;
                        }

                        .title,
                        .subtitle {
                            font-size: 15px;
                            display: block;
                            text-align: left;
                        }
                    }
                    
                    .icon {
                        max-height: 22px;
                        cursor: pointer;

                        &.filled ,
                        &.linear {
                            opacity: 1;
                            width: auto;
                            transition: 0.2s;
                        }
                    }
                }

                &.middle-info {
                    margin-bottom: 10px;
                    justify-content: space-between;

                    .price {
                        display: block;
                        font-family: $poppinsbold;
                        font-size: 20px;
                        margin-right: 20px;
                    }

                    .priceOld {
                        display: block;
                        font-family: $poppinsregular;
                        font-size: 14px;
                        margin-right: 20px;
                        text-decoration: line-through;
                        color: #A5A5A5;
                    }

                    .ref {
                        font-family: $poppinsregular;
                        font-size: 12px;
                        color: #A5A5A5;
                    }
                }

                &.bottom-info {
                    justify-content: space-between;

                    .feature {
                        .icon {
                            margin-right: 7px;
                            max-height: 18px;
                        }

                        .info-feature {
                            font-size: 15px;
                            font-family: $poppinsregular;
                        }
                    }
                }
            }

            .features {
                padding: 25px 10px 10px;

                .text {
                    color: $color-10;
                }

                .list {
                    list-style: none;
                }
            }

            .actions {
                .btn {
                    display: block;

                    .icon {
                        height: 15px;
                        vertical-align: middle;
                        margin-right: 10px;
                    }

                    &:first-child {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    &.card-help {
        background-color: $color-1;
        border-color: $color-1;
        padding: 100px 50px;
        border-radius: 10px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .block-info {
            text-align: center;
        }
    }
}