.client-content {
    .block-title {
        text-align: center;
        margin-bottom: 60px;
    }

    .box-client {
        width: 100%;
        height: 500px;
        box-shadow: -1px 3px 14px -3px #0000001A;
        -webkit-box-shadow: -1px 3px 14px -3px #0000001A;
        -moz-box-shadow: -1px 3px 14px -3px #0000001A;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 90px 75px;
        margin: 70px 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .info-centered {
            width: 100%;
            text-align: center;

            .title {
                color: $color-0;
                margin-bottom: 15px;
            }
    
            .btn {
                display: block;
            }
        }
    }

    .box {
        width: 100%;
        box-shadow: -1px 3px 14px -3px #0000001A;
        -webkit-box-shadow: -1px 3px 14px -3px #0000001A;
        -moz-box-shadow: -1px 3px 14px -3px #0000001A;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 90px 75px;
        margin-bottom: 35px;
        background-color: $color-0;

        @include media-breakpoint-down(sm) {
            padding: 30px;
        }

        .info-centered {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;

            .title {
                margin-bottom: 20px;

                .icon {
                    margin-right: 15px;
                }
            }

            .btn {
                max-width: 400px;
                width: 100%;
            }

            .text {
                margin-bottom: 30px;
            }
        }

        &.large {
            height: auto;
            
            @include media-breakpoint-down(sm) {
                padding: 30px;

                img {
                    display: none;
                }
            }

            .info-centered {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: 100%;

                .title-11 {
                    margin-bottom: 15px;
                }

                .title-8 {
                    margin-bottom: 25px;
                }

                .btn {
                    max-width: 280px;
                }
            }
        }

        &.box-form {
            height: auto;

            .info-centered {
                align-items: initial;

                .title {
                    .icon {
                        display: block;
                        margin-bottom: 15px;
                    }
                }

                .btn {
                    width: 100%;
                }

                .upload-file {
                    margin-bottom: 20px;

                    .input-group {
                        height: 130px;
                        margin-bottom: 15px;
    
                        .custom-file-label {
                            font-size: 12px;
                            color: $color-2;
                            border: 2px dotted $color-3;
                            border-radius: 10px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
    
                            span {
                                color: $color-3;
                                display: block;
                            }
    
                            &:after {
                                display: none;
                            }
                        }
    
                        .custom-file-input, .custom-file-label {
                            height: 130px;
                        }
                    }

                    .link {
                        font-size: 12px;
                        color: $color-8;
                        text-align: center;
                        display: block;
                    }
                }
            }
        }
    }

    &.tenant-area, &.owner-area {
        background-color: #FBFBFB;
        padding: 90px 0 70px;
    }
}