@font-face {
  font-family: "icons";
  src: url('./fonts/icons.eot');
  src: url('./fonts/icons.eot?#iefix') format('eot'),
  url('./fonts/icons.woff2') format('woff2'),
  url('./fonts/icons.woff') format('woff'),
  url('./fonts/icons.ttf') format('truetype');
}

@mixin icon-styles {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == cancel {
  $char: "\E001";
}
  @if $filename == facebook {
  $char: "\E002";
}
  @if $filename == flickr {
  $char: "\E003";
}
  @if $filename == instagram {
  $char: "\E004";
}
  @if $filename == left-arrow {
  $char: "\E005";
}
  @if $filename == linkedin {
  $char: "\E006";
}
  @if $filename == play-button {
  $char: "\E007";
}
  @if $filename == printing {
  $char: "\E008";
}
  @if $filename == right-arrow {
  $char: "\E009";
}
  @if $filename == right {
  $char: "\E00A";
}
  @if $filename == spotify {
  $char: "\E00B";
}
  @if $filename == twitter {
  $char: "\E00C";
}
  @if $filename == whatsapp {
  $char: "\E00D";
}
  @if $filename == youtube {
  $char: "\E00E";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @if $extend {
	@extend %icon;
  } @else {
	@include icon-styles;
  }
  content: icon-char($filename);
}
}

.icon-cancel {
  @include icon(cancel);
}
.icon-facebook {
  @include icon(facebook);
}
.icon-flickr {
  @include icon(flickr);
}
.icon-instagram {
  @include icon(instagram);
}
.icon-left-arrow {
  @include icon(left-arrow);
}
.icon-linkedin {
  @include icon(linkedin);
}
.icon-play-button {
  @include icon(play-button);
}
.icon-printing {
  @include icon(printing);
}
.icon-right-arrow {
  @include icon(right-arrow);
}
.icon-right {
  @include icon(right);
}
.icon-spotify {
  @include icon(spotify);
}
.icon-twitter {
  @include icon(twitter);
}
.icon-whatsapp {
  @include icon(whatsapp);
}
.icon-youtube {
  @include icon(youtube);
}
