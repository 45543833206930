.page-content {
    position: relative;
    padding-top: 77px;
    overflow-x: hidden;
    min-height: calc(100vh - 770px);

    @include media-breakpoint-down(sm) {
        min-height: calc(100vh - 80px);
    }
}

.MuiPaper-root {
    max-width: 475px;
    width: 100%;
    margin: 0 auto;
    box-shadow: none;
    border: 1px solid $color-3;
    margin-bottom: 30px;

    &.MuiAppBar-colorDefault {
        background-color: $color-0;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: none;

        .MuiTab-root {
            font-family: $poppinsregular;
            font-size: 16px;
            color: $color-2;
            text-transform: none;
            margin: 5px;
            border-radius: 10px;

            @include media-breakpoint-down(sm) {
                font-size: 12px;
            }
        }

        .Mui-selected {
            background-color: $color-1;
        }

        .MuiTabs-indicator {
            display: none;
        }
    }
}

.MuiBox-root {
    padding: 0;
}

.item {
    padding-bottom: 50px;

    .thumbnail {
        height: 375px;
        width: 100%;
        max-width: 535px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        height: 100%;
    }
}

.block-media {
    &.media-horizontal {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;

        li {
            &:first-child {
                a {
                    padding-left: 0;
                }
            }

            a {
                color: $color-0;
                padding: 0 10px;

                .icon {
                    font-size: 24px;
                }
            }
        }

        &.disable {
            li {
                opacity: 0.6;

                &:hover {
                    opacity: 1;
                }
            }

            &:before,
            &:after {
                opacity: 0.6;
            }
        }
    }
}

.swiper-container {
    .swiper-button-prev {
        left: 0;
    }

    .swiper-button-next {
        right: 0;
    }
}

.player-item {
    height: 615px;
    width: 100%;
    max-width: 1085px;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: -50px;
    position: relative;

    // &:after {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     background: rgba(0, 0, 0, 0.5);
    // }

    .icon {
        font-size: 126px;
        color: rgba(255, 255, 255, 0.7);
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -100px;
        margin-left: -60px;
        transition: 0.2s;
        z-index: 2;

        &:hover {
            transform: scale(1.2);
        }
    }
}

.pagination {
    justify-content: flex-end;
    margin-bottom: 80px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 60px;
    }

    .pagination-block {
        display: flex;
        list-style: none;

        .page-number {
            .link {
                font-family: $poppinslight;
                font-size: 15px;
                color: $color-7;
                padding: 5px;
                margin: 0 10px;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                @include media-breakpoint-down(sm) {
                    margin: 0 5px;
                    width: 25px;
                    height: 25px;
                }

                .icon {
                    font-size: 16px;
                    color: $color-7;
                }

                &.active {
                    border: 1px solid $color-3;
                    border-radius: 10px;
                }
            }

            &.right,
            &.left {
                .link {
                    background-color: $color-4;
                }
            }
        }
    }
}

.block-filters {
    .filters {
        background-color: $color-2;
        padding: 20px 45px;
        border-radius: 10px;

        .title {
            color: $color-1;
        }

        .filter {
            padding-top: 20px;

            .list {
                list-style: none;
                padding: 0;

                .list-item {
                    .list-link {
                        font-family: $poppinsregular;
                        font-size: 15px;
                        line-height: normal;
                        color: $color-0;
                        padding: 10px 0;
                        text-transform: none;
                        display: block;
                    }
                }
            }

            &.border-bottom {
                border-color: #707070 !important;
            }
        }
    }
}

.blog-section {
    .top-blog {
        padding: 70px 0 60px;
    }

    .block-title {
        text-align: center;
        margin-bottom: 50px;

        .title {
            margin-bottom: 15px;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 40px;
        }
    }

    .form-search {
        .form-group {
            position: relative;
            margin: 0;

            .form-control {
                border: 2px solid #e6e6e6;
                padding: 10px 50px;
                height: 50px;

                &::placeholder {
                    color: #989898 !important;
                }
                
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: #989898 !important;
                }
                
                &::-moz-placeholder { /* Firefox 19+ */
                    color: #989898 !important;
                }
                
                &:-ms-input-placeholder { /* IE 10+ */
                    color: #989898 !important;
                }
                
                &:-moz-placeholder { /* Firefox 18- */
                    color: #989898 !important;
                }
            }

            .icon {
                max-height: 18px;
                position: absolute;
                top: 15px;
                left: 20px;
            }

            .btn {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    &.internal {
        .block-title {
            text-align: left;
            margin: 0;

            .title {
                margin-bottom: 15px;
            }
        }
    }
}

.article-section {
    .content {
        // box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.09);
        // padding: 15px;

        a {
            color: #000;
        }

        ol, ul {
            font-family: $poppinsregular;
            font-size: 12px;
            line-height: 24px;
            padding-left: 50px;
        }

        img {
            max-width: 100%;
            height: auto;
            margin: 15px 0;
        }
    }
}

.page-header {
    width: 100%;
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .title-1, .text-1 {
        color: $color-0;
        margin-bottom: 30px;
    }

    &.large {
        height: auto;
        padding: 130px 0 120px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .title-7 {
            color: $color-1;
        }
    }
}

.error-content,
.success-content {
    padding: 120px 0 170px;

    @include media-breakpoint-down(sm) {
        padding: 50px 0 70px;
    }

    .image {
        @include media-breakpoint-down(sm) {
            display: block;
            max-width: 170px;
            margin: 0 auto 40px;
        }   
    }

    .description {
        display: flex;
        align-items: center;
        height: 100%;

        .info-centered {
            @include media-breakpoint-down(sm) {
                margin: 0 auto;
            }
        }

        .title {
            margin-bottom: 15px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 25px;
            }
        }
    
        .text {
            margin-bottom: 30px;
        }
    
        .btn {
            padding: 10px;
            width: 100%;
            margin-bottom: 20px;

            &.btn-1 {
                position: relative;

                .icon {
                    position: absolute;
                    top: 18px;
                    left: 30px;

                    &:before {
                        content: '';
                        display: block;
                        width: 7px;
                        height: 7px;
                        background-color: transparent;
                        border-bottom: 1px solid $color-2;
                        border-left: 1px solid $color-2;
                        transform: rotate(45deg);
                    }

                    &:after {
                        content: '';
                        display: block;
                        width: 15px;
                        height: 1px;
                        background-color: $color-2;
                        margin-top: -4px;
                    }
                }
            }
        }
    }
}

.error-content {
    background-color: $color-2;

    .description {
        .title {
            color: $color-8;
        }
    
        .text {
            color: $color-0;
        }
    }
}

.city-content {
    padding: 80px 0 0;

    @include media-breakpoint-down(sm) {
        padding: 50px 0 0;
    }

    .info {
        margin-bottom: 100px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }

        .description {
            .title-1 {
                margin-bottom: 40px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 15px;
                }
            }
    
            .text {
                margin-bottom: 20px;
            }
        }
    
        .image-group {
            height: 640px;
            position: relative;
    
            &:before,
            &:after {
                content: '';
                display: block;
                height: 170px;
                width: 30px;
                position: absolute;
            }
    
            &:after {
                background-color: $color-1;
                left: 50%;
                margin-left: 167px;
                top: 50%;
                margin-top: 10px;
            }
    
            &:before {
                background-color: $color-2;
                left: 50%;
                margin-left: -216px;
                top: 50%;
                margin-top: -160px;
            }
    
            .image {
                position: absolute;
    
                &.image-1 {
                    top: 0;
                    left: 50%;
                    margin-left: -186px;
                }
    
                &.image-2 {
                    bottom: 0;
                    left: 50%;
                    margin-left: -277px;
                }
            }
        }
    }

    .schedule {
        margin-bottom: 100px;

        .item {
            background-color: $color-1;
            padding: 50px 90px;

            .text {
                margin: 0;
            }
        }
    }

    .images-block {
        display: flex;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        .image {
            width: 50%;
            height: 480px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .districts {
        background-color: $color-4;
        padding: 70px 0;

        @include media-breakpoint-down(sm) {
            padding: 40px 0 30px;
        }

        .title-1 {
            padding: 0 0 70px;
            margin: 0;
            text-align: center;

            @include media-breakpoint-down(sm) {
                padding: 0 0 40px;
            }
        }

        .box {
            background-color: $color-0;   
            border-radius: 10px; 
            padding: 70px 90px;
            box-shadow: -1px 3px 14px -3px #0000001A;
            -webkit-box-shadow: -1px 3px 14px -3px #0000001A;
            -moz-box-shadow: -1px 3px 14px -3px #0000001A;
            margin-bottom: 35px;

            @include media-breakpoint-down(sm) {
                padding: 30px;
            }

            .block {
                padding-right: 30px;

                .title {
                    margin-bottom: 20px;
    
                    &.title-3 {
                        color: $color-5;
                        margin-top: 50px;
                    }
                }
    
                .text {
                    margin-bottom: 0;
                }
    
                .btn {
                    margin-top: 30px;
                    margin-right: 20px;
                }
            }
        }
    }

    .blog-block {
        .content {
            border: none;
        }
    }
}

.policy-content {
    .info {
        padding: 50px 0 70px;

        @include media-breakpoint-down(sm) {
            padding: 50px 0 35px;
        }

        .description {
            text-align: center;

            .block-title {
                padding: 0 160px;
                margin-bottom: 30px;

                @include media-breakpoint-down(md) {
                    padding: 0 30px;
                }
            }

            .title-4 {
                color: $color-5;
                margin-bottom: 15px;
            }

            .items {
                display: flex;
                justify-content: center;

                @include media-breakpoint-down(sm) {
                    flex-wrap: wrap;
                }

                .item {
                    background-color: $color-4;
                    width: 150px;
                    height: 130px;
                    margin-right: 15px;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;

                    @include media-breakpoint-down(sm) {
                        width: calc(100% / 2 - 15px);
                        margin-bottom: 15px;

                        &:nth-child(2n) {
                            margin-right: 0;
                        }
                    }

                    .block {
                        text-align: center;

                        .icon {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }

    .section {
        padding: 50px 0 70px;

        @include media-breakpoint-down(sm) {
            padding: 50px 0;
        }

        .title-1 {
            margin: 35px 0;

            @include media-breakpoint-down(sm) {
                margin: 0 0 35px;
            }
        }

        .block {
            .block-title {
                margin-bottom: 20px;

                .text {
                    margin-bottom: 0;
                }
            }

            ul {
                list-style: none;
            }

            a {
                font-size: 15px;
                color: $color-5;
            }

            .title-11 {
                color: $color-10;
                margin-bottom: 20px;
            }

            .title-3 {
                margin-bottom: 15px;
            }

            .data {
                display: flex;

                .text {
                    background-color: $color-0;
                    margin-right: 15px;
                    padding: 5px 15px;
                    box-shadow: 0px 5px 20px #0000000D;
                }
            }
        }

        .box {
            box-shadow: -1px 3px 14px -3px #0000001A;
            -webkit-box-shadow: -1px 3px 14px -3px #0000001A;
            -moz-box-shadow: -1px 3px 14px -3px #0000001A;
            padding: 50px 50px 80px;
            max-width: 352px;

            @include media-breakpoint-down(sm) {
                margin-top: 25px;
            }

            .icon {
                height: 58px;
                margin-bottom: 30px;
            }
        }

        &.section-gray {
            background-color: #FBFBFB;
        }
    }
}

.hidden-md {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.hidden-mobile {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.hidden-desktop {
    @include media-breakpoint-up(sm) {
        display: none;
    }
}

.show-mobile {
    display: none;

    @include media-breakpoint-down(sm) {
        display: block;
    }
}

.swiper-pagination {
    display: none;

    &.swiper-pagination-bullets {
        .swiper-pagination-bullet {
            background-color: #d6d4d3;
            width: 15px;
            height: 15px;

            &.swiper-pagination-bullet-active {
                background-color: #cecece;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        display: block;
    }
}

.location-modal {

    .location-options {

        .location-scrollbar {
            min-height: 400px;
            height: 100%;
            max-height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
        }
    
        > div {
            padding-bottom: 15px;
        }
    
        .option {
            cursor: pointer;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            background-color: #f5f5f5;
            white-space: inherit;
            color: #1a1818;
            text-transform: none;
            padding: 10px;
            margin-bottom: 15px;
            text-align: center;
    
            &.active,
            &:hover {
                background-color: #ffd300;
            }
        }
    }

    .actions {
        text-align: right;
    }
}
